import { extend } from 'vee-validate/dist/vee-validate.full';
import { MESSAGES } from '@/constants/form.constants';
import moment from "moment";

extend('mustBeInList', {
    params: ['list'],
    validate: (value, { list }) => {
        return list.includes(value);
    },
    message: MESSAGES.SELECT_FROM_LIST
});

extend('kodZakazky', {
    validate: value => {
        const regexPattern = /^[0-9]{7}[0A-NP-Z]$/;
        return regexPattern.test(value);
    },
    message: 'Kód případu musí obsahovat 8 znaků:<br>7 číslic na začátku a na konci 0 /nulu/<br>nebo písmeno F, L, R'
});

extend('kodZakazky8_10', {
    validate: value => {
        const regexPattern = /^[0-9]{7}[SBF0][LRVKPDETNX]?[KPD]?$/;
        return regexPattern.test(value);
    },
    message: 'Kód případu musí obsahovat od 8 do 10 znaků podle interního pravidla'
});

extend('kodZakazky9', {
    validate: value => {
        const regexPattern = /^[0-9]{7}[0A-NP-Z]{2}$/;
        return regexPattern.test(value);
    },
    message: 'Kód případu musí obsahovat 9 znaků:<br>7 číslic na začátku a na konci 0 /nulu/<br>nebo písmeno F, L, R a písmeno K, P, D'
});

extend('password', {
    validate: value => {
        const regexPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+|[\]{};':"\-\\,.<>/?])[A-Za-z\d!@#$%^&*()_+|[\]{};':"\-\\,.<>/?]{8,}$/;
        return regexPattern.test(value);
    },
    message: 'Heslo musí obsahovať aspoň jedno číslo, veľké písmeno, malé písmeno a špeciálny znak'
});

extend('bankovniSpojeni', {
    validate: value => {
        const regexPattern = /^[\d-]+\/\d{4}$/;
        return regexPattern.test(value);
    },
    message: 'Může obsahovat čísla a pomlčku<br>následovanou lomítkem a 4 číslicemi'
});

extend('rodneCislo', {
    validate: value => {
        const regexPattern = /^\d{6}\/\d{4}$/;
        return regexPattern.test(value);
    },
    message: 'Rodné číslo musí být ve formátu "XXXXXX/XXXX" (6 číslic / 4 číslice)'
});

extend('dateOfBirth', {
    validate: value => {
        const regexPattern = /^(0[1-9]|[12]\d|3[01])\.(0[1-9]|1[0-2])\.\d{4}$/;
        const isRegexOk = regexPattern.test(value);
        if (!isRegexOk) {
            return false;
        }
        const dateOfBirth = moment(value, 'DD.MM.YYYY');
        const today = moment();
        const yearsDiff = today.diff(dateOfBirth, 'years');
        return yearsDiff >= 16;
    },
    message: 'Zkontrolujte správnost zadaných údajů'
});