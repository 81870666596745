import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import { BootstrapVue, IconsPlugin, BootstrapVueIcons } from 'bootstrap-vue';
import Snotify, { SnotifyPosition } from 'vue-snotify';
import vueDebounce from 'vue-debounce';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import './theme.css';
import './ew-icons-font.css';
import "vue-snotify/styles/material.scss";
import VueNumeric from 'vue-numeric';
import VueMask from 'v-mask';
import {ValidationProvider, ValidationObserver, localize} from 'vee-validate/dist/vee-validate.full';
const TOKEN_KEY = 'jwt-token';
import cs from 'vee-validate/dist/locale/cs.json';
import './validators';
import VueFileAgent from 'vue-file-agent';
import 'vue-file-agent/dist/vue-file-agent.css';
import 'vue2-timepicker/dist/VueTimepicker.css';
import Multiselect from 'vue-multiselect';

axios.defaults.withCredentials = true;
axios.interceptors.request.use(
    (config) => {
      let token = localStorage.getItem(TOKEN_KEY);
      if (token) {
        config.headers['Authorization'] = `Bearer ${ token }`;
      }
      config.url = config.url.includes('http') ? config.url : process.env.VUE_APP_BACKEND_URL + config.url;
      return config;
    },

    (error) => {
      return Promise.reject(error);
    }
);

axios.interceptors.response.use(undefined, async function (error) {
    if (error) {
      const originalRequest = error.config;

      if (error.response.status === 401 && error.response.data?.errors?.includes('reauthorization')) {
        store.commit("logout");
        return router.push("/login");
      }

      if (error.response.status === 401 && originalRequest && !originalRequest._isRetry) {
        try {
          const isRefreshError = error.config?.url?.includes('refresh');
          if (isRefreshError) {
            store.commit("logout");
            return router.push("/login");
          }

          originalRequest._isRetry = true;
          const url = `/user/refresh`;
          const response = await axios.get(url);
          localStorage.setItem(TOKEN_KEY, response.data?.accessToken);
          return axios.request(originalRequest);
        } catch (err) {
          console.log(err);
          store.commit("logout");
          return router.push("/login");
        }
      }
      throw error;
    }
});

const optionsNotify = {
  toast: {
    position: SnotifyPosition.rightBottom,
    showProgressBar: false,
    timeout: 3000,
  }
}

// vee-validate
localize({cs});
localize('cs')
Vue.component('ValidationObserver', ValidationObserver);
Vue.component('ValidationProvider', ValidationProvider);

Vue.config.productionTip = false;
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(BootstrapVueIcons);
Vue.use(Snotify, optionsNotify);
Vue.use(vueDebounce, {
  defaultTime: '700ms'
});
Vue.use(VueMask);
Vue.use(VueFileAgent);
Vue.use(VueNumeric);
Vue.component('multiselect', Multiselect);

new Vue({
  store,
  router,
  render: (h) => h(App),
}).$mount("#app");
