<template>
  <header id="main-header">
    <b-col cols="12" class="p-0">
      <b-navbar toggleable="sm" type="dark" class="d-flex align-content-between">
        <b-collapse id="nav-collapse1" is-nav class="text-left grow-5">
          <b-navbar-nav>
            <b-nav-item-dropdown left class="section-button">
              <template #button-content>
                <span class="fs-25px text-white">{{routeName}}</span>
              </template>
              <b-dropdown-item v-for="page in allowedPagesBlock1" :key="page.url" :to="'/'+page.url" @click="clickMenu">
                {{ page.title }}
              </b-dropdown-item>
              <hr v-if="allowedPagesBlock2.length">
              <b-dropdown-item v-for="page in allowedPagesBlock2" :key="page.url" :to="'/'+page.url" @click="clickMenu">
                {{ page.title }}
              </b-dropdown-item>
              <hr v-if="allowedPagesBlock3.length">
              <b-dropdown-item v-for="page in allowedPagesBlock3" :key="page.url" :to="'/'+page.url" @click="clickMenu">
                {{ page.title }}
              </b-dropdown-item>
              <hr v-if="allowedAdminPages.length">
              <b-dropdown-item v-for="page in allowedAdminPages" :key="page.url" :to="'/'+page.url" @click="clickMenu">
                {{ page.title }}
              </b-dropdown-item>
            </b-nav-item-dropdown>
          </b-navbar-nav>
          <b-navbar-nav>
            <template v-if="isAllowKalendar">
              <b-nav-item
                  to="/kalendar"
                  class="ml-2"
                  title="Kalendář"
              >
                <b-icon-calendar3 font-scale="1.1" class="cursor-pointer color-white"/>
              </b-nav-item>
            </template>
          </b-navbar-nav>
        </b-collapse>

        <b-collapse is-nav class="d-flex flex-column align-items-end flex-grow-0 mr-4">
          <b-navbar-nav class="ml-auto">
            <template v-if="getUserNumberOZ">
              <template v-if="$store.getters.isCanSeeAllZakazky">
                <b-nav-item
                    :to="`/dohled-nad-oz/ukolovani-zastupcu?type=${FILTER_TYPES.NENAPLANOVANE_AKTIVITY}`"
                    v-b-tooltip.hover="{
                      title: 'Nenaplánované aktivity',
                      delay: { show: 200, hide: 200 },
                      variant: 'secondary'
                    }"
                    class="mr-2"
                >
                  <b-icon-bootstrap-reboot font-scale="1.1" class="cursor-pointer color-white"/>
                </b-nav-item>
                <b-nav-item
                    :to="`/dohled-nad-oz/ukolovani-zastupcu?type=${FILTER_TYPES.NESPLNENE_AKTIVITY}`"
                    v-b-tooltip.hover="{
                      title: 'Nesplněné aktivity',
                      delay: { show: 200, hide: 200 },
                      variant: 'secondary'
                    }"
                    class="mr-4"
                >
                  <b-icon-alarm font-scale="1.1" class="cursor-pointer color-white"/>
                </b-nav-item>
              </template>
              <template v-else>
                <b-nav-item
                    to="/obchodni-rozpracovanost"
                    v-b-tooltip.hover="{
                      title: 'Obchodní rozpracovanost',
                      delay: { show: 200, hide: 200 },
                      variant: 'secondary'
                    }"
                    class="mr-2"
                >
                  <b-icon-kanban font-scale="1.1" class="cursor-pointer color-white"/>
                </b-nav-item>
                <b-nav-item
                    to="/nenaplanovane-aktivity"
                    :disabled="!nenaplanovaneAktivityCount"
                    v-b-tooltip.hover="{
                      title: 'Nenaplánované aktivity (' + nenaplanovaneAktivityCount + ')',
                      delay: { show: 200, hide: 200 },
                      variant: 'secondary'
                    }"
                    class="mr-2"
                    :class="[!nenaplanovaneAktivityCount ? 'cursor-not-allowed' : '']"
                >
                  <b-icon-bootstrap-reboot font-scale="1.1" class="cursor-pointer" :class="[!nenaplanovaneAktivityCount ? '' : 'color-white']"/>
                </b-nav-item>
                <b-nav-item
                    to="/nesplnene-aktivity"
                    :disabled="!nesplneneAktivityCount"
                    v-b-tooltip.hover="{
                      title: 'Nesplněné aktivity (' + nesplneneAktivityCount + ')',
                      delay: { show: 200, hide: 200 },
                      variant: 'secondary'
                    }"
                    class="mr-4"
                    :class="[!nesplneneAktivityCount ? 'cursor-not-allowed' : '']"
                >
                  <b-icon-alarm font-scale="1.1" class="cursor-pointer" :class="[!nesplneneAktivityCount ? '' : 'color-white']"/>
                </b-nav-item>
              </template>
            </template>

            <template v-if="getUserNumberOZ">
              <b-nav-item
                  href="#"
                  :disabled="!nesplneneAktivityPoTerminuCount"
                  v-b-tooltip.hover="{
                    title:'Nesplněné aktivity po terminu (' + nesplneneAktivityPoTerminuCount + ')',
                    delay: { show: 200, hide: 200 },
                    variant: 'secondary'
                  }"
                  class="mr-2"
                  :class="[!nesplneneAktivityPoTerminuCount ? 'cursor-not-allowed' : '']"
                  @click.prevent="showNesplneneAktivityPoTerminuModal"
              >
                <b-icon-exclamation-octagon font-scale="1.1" class="cursor-pointer" />
              </b-nav-item>
              <b-nav-item
                  href="#"
                  :disabled="!nejblizsichAktivitDatesCount"
                  v-b-tooltip.hover="{
                    title: 'Plán nejbližších aktivit (' + nejblizsichAktivitDatesCount + ')',
                    delay: { show: 200, hide: 200 },
                    variant: 'secondary'
                  }"
                  class="mr-4"
                  :class="[!nejblizsichAktivitDatesCount ? 'cursor-not-allowed' : '']"
                  @click.prevent="showPlanNejblizsichAktivitModal"
              >
                <b-icon-ui-checks-grid font-scale="1.1" class="cursor-pointer"/>
              </b-nav-item>
            </template>
            <b-nav-item :to="'/knihovna/zakladni-dokumenty'" title="Knihovna" class="mr-2">
              <b-icon-book font-scale="1.3" class="cursor-pointer mt-5px color-white"/>
            </b-nav-item>
          </b-navbar-nav>
        </b-collapse>

        <b-collapse id="nav-collapse2" is-nav class="d-flex flex-column align-items-end flex-grow-0">
          <b-navbar-nav class="ml-auto">
            <b-nav-item-dropdown right>
              <template #button-content>
                <span>{{ getUserNamesAndTitles }}</span>
              </template>
              <b-dropdown-text class="text-nowrap">Login: <span class="font-weight-bold">{{ userInfo.login }}</span></b-dropdown-text>
              <b-dropdown-item href="#" disabled>Nastavení profilu</b-dropdown-item>

              <b-dropdown-divider />
              <b-dropdown-item href="mailto:morozov@trienergo.cz?subject=FVE%20creator.%20Report%20an%20error">Nahlásit chybu</b-dropdown-item>
              <b-dropdown-item href="#" @click.prevent="showCoJeNovehoModal">Co je nového...</b-dropdown-item>
              <b-dropdown-divider />

              <b-dropdown-item href="#" @click.prevent="logout">Odhlásit se</b-dropdown-item>
            </b-nav-item-dropdown>
          </b-navbar-nav>
          <div class="pr-2 header-user">
            {{ userInfo.position }}
          </div>
        </b-collapse>
      </b-navbar>
    </b-col>
  </header>
</template>


<script>

import router from "@/router";
import {URL_PAGES} from "@/constants/url.constants";
import axios from "axios";
import { mapActions, mapGetters } from "vuex";
import _ from "lodash";
import {FILTER_TYPES} from "@/constants/form.constants";

export default {
  name: "NavBar",
  props: {
    routeName: {type: String},
  },
  computed: {
    FILTER_TYPES() {
      return FILTER_TYPES
    },
    ...mapGetters(['userInfo', 'getPagesEntitlements', 'getUserNamesAndTitles', 'getModalsEntitlements', 'getUserNumberOZ',
      'nesplneneAktivityPoTerminuCount', 'nejblizsichAktivitDatesCount', 'nenaplanovaneAktivityCount', 'nesplneneAktivityCount',
    ]),
    isAllowKalendar() {
      return this.$store.getters.isKalendarPageAllowed;
    },
  },
  data() {
    return {
      allowedPagesBlock1: [],
      allowedPagesBlock2: [],
      allowedPagesBlock3: [],
      allowedAdminPages: [],
    }
  },
  methods: {
    ...mapActions(["getUserInfo"]),
    async logout() {
      try {
        const url = `/user/logout`;
        await axios.get(url);
        this.$store.commit('logout');
        await router.push('/login');
      } catch (e) {
        console.log(e)
      }
    },
    showCoJeNovehoModal() {
      this.$emit('show-co-je-noveho');
    },
    showNesplneneAktivityPoTerminuModal() {
      this.$emit('show-nesplnene-aktivity-po-terminu');
    },
    showPlanNejblizsichAktivitModal() {
      this.$emit('show-plan-nejblizsich-aktivit');
    },
    async clickMenu() {
      await this.$store.dispatch('getActivitiesCount')
    },
  },
  async mounted() {
    await this.getUserInfo();
    await this.$store.dispatch('getActivitiesCount');
    const entitlementsPages = this.getPagesEntitlements || [];
    const entitlementsModals = this.getModalsEntitlements || [];
    entitlementsPages.forEach(ent => {
      if (URL_PAGES[ent]) {
        if (URL_PAGES[ent].isAdmin) {
          return this.allowedAdminPages.push(URL_PAGES[ent]);
        } else if (URL_PAGES[ent].isBlock2) {
          return this.allowedPagesBlock2.push(URL_PAGES[ent]);
        } else if (URL_PAGES[ent].isBlock3) {
          return this.allowedPagesBlock3.push(URL_PAGES[ent]);
        }
        this.allowedPagesBlock1.push(URL_PAGES[ent]);
      } else if (ent === 'administrator') {
        if (entitlementsModals.includes('logy')) {  // logy page isn't a modal, but it saves as a modal in entitlements
          return this.allowedAdminPages.push(URL_PAGES['logy']);
        }
      }
    });
    this.allowedPagesBlock1 = _.sortBy(this.allowedPagesBlock1, ['order']);
    this.allowedPagesBlock2 = _.sortBy(this.allowedPagesBlock2, ['order']);
    this.allowedPagesBlock3 = _.sortBy(this.allowedPagesBlock3, ['order']);
    this.allowedAdminPages = _.sortBy(this.allowedAdminPages, ['order']);
  },
  components: {},
};
</script>

<style scoped>
 .section-button {
   border: 2px solid white;
   padding: 3px 5px
 }

 .grow-5 {
   flex-grow: 5;
 }
</style>