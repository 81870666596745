export const entitlCodesForPages = {
  OBCHOD: 'obchod',
  SLEDOVANI_ZAKAZEK: 'sledovaniZakazek',
  REPORT_INSTALACE_A_TECHNOLOGIE: 'reportInstalaceATechnologie',
  UZIVATELE: 'uzivatele',
  ADMINISTRATOR: 'administrator',
  PREHLED_CISEL: 'prehledCisel',
  FINANCNI_ANALYZA: 'financniAnalyza',
  DOHLED_NAD_OZ: 'dohledNadOz',
  KALENDAR: 'kalendar',
  SKLAD: 'sklad',
  SERVIS: 'servis',
  LOGY: 'logy',
  USCHOVNA: 'uschovna',
}

export const entitlCodesForModals = {
  OBCHODNI_PRIPAD: 'novaZakazka',
  OBHLIDKOVY_FORMULAR: 'obhlidkovyFormular',
  KONTROLA_PV_SOL: 'kontrolaPVSol',
  POSTUP_ZAKAZKY: 'postupZakazky',
  TERMINY_PLNENI_KROKU_ZAKAZKY: 'terminyPlneniKrokuZakazky',
  TYM_ZAKAZKY: 'tymZakazky',
  TYM_ZAKAZKY_INSTALACE: 'tymZakazkyInstalace',
  SOUSTAVA_TECHNOLOGIE: 'soustavaTechnologie',
  PROFIL_UZIVATELE: 'profilUzivatele',
  ZMENIT_HESLO_UZIVATELE: 'zmenitHesloUzivatele',
  UPRAVIT_PRISTUP: 'upravitPristup',
  STAV_OBCHODNIHO_PRIPADU: 'stavObchodnihoPripadu',
  POZNAMKY_K_OBCHODNIMU_PRIPADU: 'poznamkyKobchodnimuPripadu',
  POZNAMKY_K_SERVISU: 'poznamkyKservisu',
  GDPR_RODINE_PRISLUSNICI: 'gdprRodinnePrislusnici',
  GDPR_SERVICE: 'gdprService',
  SMLOUVA_FAKTURA: 'smlouvaDilo',
  LOGY: 'logy',
  EDIT_APP_HISTORY: 'editAppHistory',
  PREDANI_OP_MEZI_OZ: 'predaniOpMeziOz',
  ZODPOVEDNOST_V_KNIHOVNE: 'zodpovednostVKnihovne',
  OPRAVA_KODU_OP: 'opravaKoduOP',
  EXPORT_DO_EXCEL: 'exportDoExcel',
  AKTIVITY_OBCHODU: 'aktivityObchodu',
  VSE_VYKAZY_PRACE: 'vseVykazyPrace',
  DODATKY_SMLOUVE: 'dodatkySmlouve',
  SERVISNI_PRIPAD: 'servisniPripad',
  SABLONY_DATATABULKY: 'sablonyDatatabulky',
}

export const STAV_CODES = {
  VYBEROV_RIZENI: 'vyberoveRizeni',
  ZAEM_TRVA: 'interestAfter',
  PROBIHA_ZPRACOVANI: 'offerProcessing',
  NABIDKA_ZASLANA: 'offerSentClient',
  VYHRA: 'victory',
  PROHRA: 'loss',
  ZRUSENO: 'canceled',
  NEMA_ZAJEM: 'customerNotInterested',
};
