<template>
    <footer id="footer" class="frame-background-dark d-flex justify-content-between">
        <div class="d-flex align-items-center justify-content-center w-200px">
            <a href="https://www.trienergo.cz/" title="Přejít na web www.trienergo.cz" target="_blank">
                <img class="logoFooter" src="@/assets/trienergo-logo-inv.svg" alt="Trienergo logo" />
            </a>
        </div>
        <div class="d-flex flex-row sociallinks align-items-center justify-content-center">
            <a class="sociallinks-link sociallinks-link-facebook" href="https://www.facebook.com/trienergo.cz" rel="noopener" target="_blank">
                <span class="sociallinks-link-icon ew-icon-facebook"></span>
            </a>
            <a class="sociallinks-link sociallinks-link-instagram" href="https://www.instagram.com/trienergo/" rel="noopener" target="_blank">
                <span class="sociallinks-link-icon ew-icon-instagram"></span>
            </a>
            <a class="sociallinks-link sociallinks-link-linkedin" href="https://www.linkedin.com/company/trienergo/" rel="noopener" target="_blank">
                <span class="sociallinks-link-icon ew-icon-linkedin"></span>
            </a>
            <a class="sociallinks-link sociallinks-link-youtube" href="https://www.youtube.com/@trienergo" rel="noopener" target="_blank">
                <span class="sociallinks-link-icon ew-icon-youtube"></span>
            </a>
            <a class="sociallinks-link sociallinks-link-pinterest" href="https://cz.pinterest.com/trienergo/" rel="noopener" target="_blank">
                <span class="sociallinks-link-icon ew-icon-pinterest"></span>
            </a>

        </div>
        <div class="pr-2 d-flex align-items-center">
            <span class="text-right fs-13px">Copyright © {{getYear}} Tri energo s.r.o.</span>
        </div>
    </footer>
</template>


<script>

export default {
  name: "Footer",
  computed: {
      getYear() {
          const d = new Date();
          return d.getFullYear();
      },
  },
  data() {
    return {

    }
  },
};
</script>

<style scoped>
.frame-background-dark {
    color: #fff;
    background-color: #323341;
}
.sociallinks a.sociallinks-link {
    display: block;
    color: #ee801f;
}
.sociallinks .sociallinks-link span {
    font-size: 1.5rem;
}
.sociallinks-link-icon {
    font-size: 1.25rem;
    opacity: 0.8;
}
.sociallinks .sociallinks-link {
    margin: 0 0.5rem;
}
.logoFooter {
    height: 30px;
}
</style>