// @ts-ignore
import moment from "moment";
// @ts-ignore
import _ from "lodash";
import Axios from "axios";
import {FILTER_TYPES} from "../constants/form.constants";
import {filesize} from "filesize";

export default {
    methods: {
        getFormattedDate(date, format = "DD.MM.YYYY", placeholder = '') {
            const isDate = moment(date).isValid();
            if (!isDate && !placeholder) {
                return date;
            }

            return date && moment(date).format(format) || placeholder;
        },
        getFormattedDateCheckDisabled(date, item, key) {
            return _.get(item, `disabledForEdit[${key}]`) ? '---' : this.getFormattedDate(date);
        },
        handleInputNumbers(value, tempValue, minRange = 0, maxRange = 100) {
            if (tempValue === '') {
                value = null;
            } else if (/^\d+$/.test(tempValue) && _.inRange(tempValue, minRange, maxRange)) {
                value = tempValue;
            } else {
                tempValue = value;
            }
            return {value, tempValue};
        },
        joinName(user) {
            const {titleBeforeName, titleAfterName, name, clientName, lastName, clientLastName} = user;
            const joinedName = [titleBeforeName, name || clientName, lastName || clientLastName].filter(n => n).join(' ');

            return titleAfterName ? `${joinedName}, ${titleAfterName}` : joinedName;
        },
        joinNameFioTitles(user) {
            const {titleBeforeName, titleAfterName, name, clientName, lastName, clientLastName} = user;
            let joinedName = [lastName || clientLastName, name || clientName].filter(n => n).join(' ');
            joinedName += titleBeforeName ? `, ${titleBeforeName}` : '';
            joinedName += titleAfterName ? `, ${titleAfterName}` : '';
            return joinedName;
        },
        getState({invalid, validated}, skip = false) {
            if (skip) return null;
            return validated ? !invalid : null;
        },
        getClassByValidatorState({invalid, validated}) {
            return validated && !invalid ? '' : 'form-control is-invalid';  // 'form-control is-valid' - for green
        },
        getTimeActivityString(timeObj) {
            if (!timeObj) return '';

            const isObject = typeof timeObj === 'object';
            return isObject ? `${timeObj.HH || '--'}:${timeObj.mm || '--'}` : '';
        },
        $lodashGet(val1, val2, defaultValue) {
            return _.get(val1, val2, defaultValue);
        },
        async getConstantsFromServer(constants) {
            const urlConst = "/common/getConstants";
            const {data: {dataConst}} = await Axios.post(urlConst, { constants });
            return dataConst || {};
        },
        async getOkresyList() {
            const url = `/obchod/getOkresyListNames`;
            const {data: {data = {}}} = await Axios.get(url, {});
            return data.okresy || [];
        },
        getFilteredByOzUsersFilterTypes(TYPES) {
            let types = [...TYPES];
            if (this.$store.getters.isCanSeeAllZakazky || !this.$store.getters.getUserNumberOZ) return types;

            if (!this.$store.getters.isHaveActiveOzUsersGroup) {
                types = types.filter(t => t.value !== FILTER_TYPES.OZ_USERS_ACTIVE);
            }
            if (!this.$store.getters.isHaveDeactivatedOzUsersGroup) {
                types = types.filter(t => t.value !== FILTER_TYPES.OZ_USERS_DEACTIVATED);
            }
            return types;
        },
        resetScrollTop() {
            const tableEl = this.$el.querySelector('.table-responsive');
            if (!tableEl) return;
            tableEl.scrollTop = 0;
        },
        getFullAddress(addressInfo) {
            const {cityInstallation, addressInstallation, cislaDomu, cisloOrientacni, evidencniCisloDomu, zip, standsOnProperty, cadastralTerritory} = addressInfo;
            const address = [];
            const joinedDomNumbers = this.getJoinedDomNumbers(cislaDomu, cisloOrientacni, evidencniCisloDomu);
            if (addressInstallation || joinedDomNumbers) {
                const addrDom = `${addressInstallation || ''} ${joinedDomNumbers || ''}`;
                address.push(`${addrDom.trim()},`);
            }
            address.push(zip || '');
            address.push(cityInstallation || '');
            const addressAdditional = [];
            if (standsOnProperty || cadastralTerritory) {
                addressAdditional.push('(');
                addressAdditional.push(standsOnProperty ? `${standsOnProperty}, ` : '');
                addressAdditional.push(cadastralTerritory || '');
                addressAdditional.push(')');
            }
            address.push(addressAdditional.join(''));
            return address.filter(a => a).join(' ');
        },
        getJoinedDomNumbers(cislaDomu, cisloOrientacni, evidencniCisloDomu) {
            const numbers = [];
            const isExistCislaDomu = !_.isEmpty(cislaDomu);
            const isExistCisloOrientacni = !_.isEmpty(cisloOrientacni);
            const isExistEvidencniCisloDomu = !_.isEmpty(evidencniCisloDomu);

            if (isExistCislaDomu) numbers.push(cislaDomu);
            if (isExistCislaDomu && isExistCisloOrientacni) numbers.push('/');
            if (isExistCisloOrientacni) numbers.push(cisloOrientacni);
            if (isExistEvidencniCisloDomu) numbers.push(` (č.ev. ${evidencniCisloDomu})`);
            return numbers.join('').trim();
        },
        getShortAddress(addressInfo) {
            const {cityInstallation, addressInstallation, cislaDomu, cisloOrientacni, evidencniCisloDomu, zip} = addressInfo;
            const address = [];
            const joinedDomNumbers = this.getJoinedDomNumbers(cislaDomu, cisloOrientacni, evidencniCisloDomu);
            if (addressInstallation || joinedDomNumbers) {
                const addrDom = `${addressInstallation || ''} ${joinedDomNumbers || ''}`;
                address.push(`${addrDom.trim()},`);
            }
            if (zip) {
                let zipCity = zip.replace(' ', '').replace(/(\d{3})(\d{2})/, '$1 $2');
                address.push(zipCity || '');
            }
            address.push(cityInstallation || '');
            return address.filter(a => a).join(' ');
        },
        formatMoney(value, additional = 'Kč') {
            return value ? `${value.toLocaleString("fi-FI")} ${additional}` : ``;
        },
        formatMoneyZero(value, additional = 'Kč') {
            value = value || 0;
            return `${value.toLocaleString("fi-FI")} ${additional}`;
        },
        formatPercent(value, additional = '%') {
            return value ? `${value} ${additional}` : '';
        },
        formatPercentZero(value, number = 2) {
            return `${value ? value.toFixed(number) : 0} %`;
        },
        getKrajName(kraj, krajsList = []) {
            const result = krajsList.find(el => el.value === kraj);
            return result ? result.text : '';
        },
        getOkres(item) {
            return item.okres ? `okres ${item.okres}` : '';
        },
        getTextByKey(key, data = []) {
            const result = data.find(el => el.value === key);
            return result ? result.text : '';
        },
        getEmailLink(email) {
            return `<a href=mailto:${email}>${email}</a>`;
        },
        getDateOfBirthFromRodneCislo(rodneCislo) {
            const datePart = _.first(rodneCislo.split('/'));
            const dateElements = _.chunk(datePart.split(''), 2).map(arr => arr.join(''));
            let [ YY, MM, DD ] = dateElements;
            const currYear = moment().format('YY');
            const YYYYY = +YY >= +currYear ? `19${YY}` : `20${YY}}`;
            if (+MM > 12) {
                if (MM[0] === '5') {
                    MM = MM.replace(/^5/, 0);
                } else if (MM[0] === '6') {
                    MM = MM.replace(/^6/, 1);
                }
            }
            return `${DD}.${MM}.${YYYYY}`;
        },
        getHHmmFromMinutes(totalMinutes = 0) {
            const duration = moment.duration(totalMinutes, 'minutes');
            const hh = (duration.years() * (365 * 24)) + (duration.months() * (30 * 24)) + (duration.days() * 24) + (duration.hours());
            const mm = duration.minutes();
            return hh.toString().padStart(2, '0') + ':' + mm.toString().padStart(2, '0');
        },
        getObchodniZastupceAndManagerKoordinatorName(item, managerKoordinatorObjById) {
            const importedObchodniZastupceName = _.get(item, 'obchodniZastupceParseComment', '');
            const ozUserName = _.get(item, `obchodniZastupceFullName`, '') || importedObchodniZastupceName || '';
            const managerKoordinatorName = this.managerCoordinatorNameById(item, managerKoordinatorObjById);
            return `${ozUserName}${managerKoordinatorName}`;
        },
        managerCoordinatorNameById(item, managerKoordinatorObjById) {
            const name = _.get(managerKoordinatorObjById, `${item.managerKoordinatorId}`, null);
            return name ? `<br>(${name})` : '';
        },
        getHHMMHod(timeHHMM) {
            let timeMs = this.getTimeMs(timeHHMM.timeDo) - this.getTimeMs(timeHHMM.timeOd);
            timeMs = timeMs < 0 ? 0 : timeMs;
            const minutes = Math.ceil(timeMs / (60 * 1000));
            return `${this.getHHmmFromMinutes(minutes)} hod.`;
        },
        getTimeMs(time){
            return moment().set({hour: parseInt(time.HH), minute: parseInt(time.mm)}).valueOf();
        },
        fileSize(size) {
            return filesize(size, { locale: "cs", round: 1 });
        },
        escapeLikeTagsText(text) { // vue2 redactor doesn't work correctly with like tags code (ex. <email@email.com>)
            if (!text) return '';
            const emailPattern = /<([^<>]*@[^<>]*)>/g;

            let replacedText = text.replace(emailPattern, '[$1]');
            // more replace
            return replacedText;
        }
    }
}