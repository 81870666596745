import { parseJwt } from './common';
import {entitlCodesForModals, entitlCodesForPages} from "@/constants/system.constants";

const checkAccessModal = (token, nameModal) => {
  const entitlements = parseJwt(token).entitlements;
  return !!entitlements?.modals?.includes(nameModal);
}

// const checkAccessModals = (token, nameModals) => {
//   const entitlements = parseJwt(token).entitlements;
//   return nameModals.includes(entitlements?.modals);
// }

const checkAccessPages = (token, namePage) => {
  const entitlements = parseJwt(token).entitlements;
  return !!entitlements?.pages?.includes(namePage);
}

const getters = {
  getEntitlements: (state) => parseJwt(state.token).entitlements,
  getPagesEntitlements: (state) => parseJwt(state.token)?.entitlements?.pages,
  getModalsEntitlements: (state) => parseJwt(state.token)?.entitlements?.modals,

  // Access to modals
  isObchodniPripadModalAllowed: (state) => checkAccessModal(state.token, entitlCodesForModals.OBCHODNI_PRIPAD),
  isObhlidkovyFormularModalAllowed: (state) => checkAccessModal(state.token, entitlCodesForModals.OBHLIDKOVY_FORMULAR),
  isKontrolaPVSolModalAllowed: (state) => checkAccessModal(state.token, entitlCodesForModals.KONTROLA_PV_SOL),
  isPostupZakazkyModalAllowed: (state) => checkAccessModal(state.token, entitlCodesForModals.POSTUP_ZAKAZKY),
  isTymZakazkyModalAllowed: (state) => checkAccessModal(state.token, entitlCodesForModals.TYM_ZAKAZKY),
  isTymZakazkyInstalaceModalAllowed: (state) => checkAccessModal(state.token, entitlCodesForModals.TYM_ZAKAZKY_INSTALACE),
  isTerminyPlneniKrokuZakazkyModalAllowed: (state) => checkAccessModal(state.token, entitlCodesForModals.TERMINY_PLNENI_KROKU_ZAKAZKY),
  isSoustavaTechnologieModalAllowed: (state) => checkAccessModal(state.token, entitlCodesForModals.SOUSTAVA_TECHNOLOGIE),
  ispPofilUzivateleModalAllowed: (state) => checkAccessModal(state.token, entitlCodesForModals.PROFIL_UZIVATELE),
  isZmenitHesloUzivateleModalAllowed: (state) => checkAccessModal(state.token, entitlCodesForModals.ZMENIT_HESLO_UZIVATELE),
  isUpravitPristupModalAllowed: (state) => checkAccessModal(state.token, entitlCodesForModals.UPRAVIT_PRISTUP),
  isStavObchodnihoPripaduAllowed: (state) => checkAccessModal(state.token, entitlCodesForModals.STAV_OBCHODNIHO_PRIPADU),
  isPoznamkyKobchodnimuPripaduAllowed: (state) => checkAccessModal(state.token, entitlCodesForModals.POZNAMKY_K_OBCHODNIMU_PRIPADU),
  isPoznamkyKservisuAllowed: (state) => checkAccessModal(state.token, entitlCodesForModals.POZNAMKY_K_SERVISU),
  isGDPRRodinnePrislusniciAllowed: (state) => checkAccessModal(state.token, entitlCodesForModals.GDPR_RODINE_PRISLUSNICI),
  isGDPRServiceAllowed: (state) => checkAccessModal(state.token, entitlCodesForModals.GDPR_SERVICE),
  isSmlouvaFakturaAllowed: (state) => checkAccessModal(state.token, entitlCodesForModals.SMLOUVA_FAKTURA),
  isAppHistoryEditAllowed: (state) => checkAccessModal(state.token, entitlCodesForModals.EDIT_APP_HISTORY),
  isPredaniOpMeziOz: (state) => checkAccessModal(state.token, entitlCodesForModals.PREDANI_OP_MEZI_OZ),
  isZodpovednostVKnihovne: (state) => checkAccessModal(state.token, entitlCodesForModals.ZODPOVEDNOST_V_KNIHOVNE),
  isOpravaKoduOP: (state) => checkAccessModal(state.token, entitlCodesForModals.OPRAVA_KODU_OP),
  isExportDoExcel: (state) => checkAccessModal(state.token, entitlCodesForModals.EXPORT_DO_EXCEL),
  isAktivityObchoduAllowed: (state) => checkAccessModal(state.token, entitlCodesForModals.AKTIVITY_OBCHODU),
  isVseVykazyPraceAllowed: (state) => checkAccessModal(state.token, entitlCodesForModals.VSE_VYKAZY_PRACE),
  isDodatkySmlouveAllowed: (state) => checkAccessModal(state.token, entitlCodesForModals.DODATKY_SMLOUVE),
  isServisniPripadAllowed: (state) => checkAccessModal(state.token, entitlCodesForModals.SERVISNI_PRIPAD),
  isSablonyDatatabulkyAllowed: (state) => checkAccessModal(state.token, entitlCodesForModals.SABLONY_DATATABULKY),

  // Access to pages
  isSledovaniZakazekPageAllowed: (state) => checkAccessPages(state.token, entitlCodesForPages.SLEDOVANI_ZAKAZEK),
  isReportInstalaceATechnologiePageAllowed: (state) => checkAccessPages(state.token, entitlCodesForPages.REPORT_INSTALACE_A_TECHNOLOGIE),
  isPrehledCiselPageAllowed: (state) => checkAccessPages(state.token, entitlCodesForPages.PREHLED_CISEL),
  isDohledNadOzPageAllowed: (state) => checkAccessPages(state.token, entitlCodesForPages.DOHLED_NAD_OZ),
  isFinancniAnalyzaPageAllowed: (state) => checkAccessPages(state.token, entitlCodesForPages.FINANCNI_ANALYZA),
  isSkladPageAllowed: (state) => checkAccessPages(state.token, entitlCodesForPages.SKLAD),
  isUzivatelePageAllowed: (state) => checkAccessPages(state.token, entitlCodesForPages.UZIVATELE),
  isLogyPageAllowed: (state) => checkAccessModal(state.token, entitlCodesForPages.LOGY),
  isObchodPageAllowed: (state) => checkAccessPages(state.token, entitlCodesForPages.OBCHOD),
  isKalendarPageAllowed: (state) => checkAccessPages(state.token, entitlCodesForPages.KALENDAR),
  isServisPageAllowed: (state) => checkAccessPages(state.token, entitlCodesForPages.SERVIS),
  isUschovnaAllowed: (state) => checkAccessPages(state.token, entitlCodesForPages.USCHOVNA),
};


export default {
  getters,
};

