<template>
  <b-modal
      v-model="showLocal"
      scrollable centered hide-footer no-close-on-backdrop
      dialog-class="whatNew-form"
      body-class="h-100 divH100 mb-2"
      @hidden="$emit('close-modal')"
  >
    <template #modal-title>
      <div class="text-center">
        <h6 class="text-nowrap w-100">Co je nového...</h6>
      </div>
    </template>

    <b-overlay :show="isLoading" rounded="sm">
      <div v-if="isCanEditAppHistory" class="modalWindow-w100 h-calc-modal" @click.stop>
        <vue-editor
          id="editor1"
          v-model="appHistory"
        />
        <div class="d-flex justify-content-center w-100>">
          <b-button @click="onSubmit()" class="mt-4 mb-3" variant="primary">Uložit data a zavřít</b-button>
        </div>
      </div>

      <div v-else
          v-html="appHistory"
          class="appHistory"
      />
    </b-overlay>
  </b-modal>
</template>

<script>
import axios from "axios";
import { VueEditor } from "vue2-editor";

export default {
  components: {
    VueEditor
  },
  computed: {
    isCanEditAppHistory() {
      return this.$store.getters.isAppHistoryEditAllowed;
    }
  },
  props: {
    show: { type: Boolean },
  },
  data() {
    return {
      appHistory: null,
      isLoading: false,
      showLocal: false,
    }
  },
  async mounted() {
    this.isLoading = true;
    await this.getSavedData();
    this.isLoading = false;
    this.showLocal = this.show;
  },
  methods: {
    async getSavedData() {
      const url = `/admin/getAppHistory`;
      const {data: {data}} = await axios.get(url, {});
      this.appHistory = data;
    },
    async saveAppHistory() {
      try {
        const url = `/admin/saveAppHistory`;
        const {data} = await axios.post(url, {history: this.appHistory});
        this.$snotify[data.status](data.data.message);
        return data.status;
      } catch (e) {
        console.log('saveAppHistory', e.response?.data);
        this.$snotify['error'](e.response?.data?.message || 'Internal Server Error');
      }
    },
    async onSubmit() {
      try {
        const status = await this.saveAppHistory();
        if (status === 'success') {
          this.$emit('close-modal');
        }
      } catch (e) {
        console.error('Co je nového... save', e);
        throw new Error()
      }
    },
  },
}
</script>

<style scoped>
  h6 {
    font-weight: 500;
    font-size: 28px;
  }
  .divH100 > div {
    height: 100%;
  }
  .h-calc-modal {
    height: calc(100% - 70px);
  }
  .appHistory {
    width: 100%;
    height: 100%;
  }



</style>