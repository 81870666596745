<template>
  <b-modal
      v-if="isHaveAccess"
      v-model="showLocal"
      scrollable centered hide-footer no-close-on-backdrop
      dialog-class="stavObhodnihoPripadu-form"
      @hidden="$emit('close-modal')"
  >
    <template #modal-title>
      <div class="text-center">
        <h6 class="text-nowrap w-100">Stav obchodního případu č. {{ kodZakazky }}</h6>
      </div>
    </template>
    <b-overlay :show="isLoading" rounded="sm">
      <div class="modalWindow-w100" @click.stop>
        <ValidationObserver v-slot="{ handleSubmit }">
          <b-form @submit.stop.prevent="handleSubmit(onSubmit)">
            <div class="d-flex">
              <b-form-group class="label-1 w-50" label="Stav" label-for="stav">
                <ValidationProvider rules="required" name="'Stav'" mode="lazy" v-slot="{ classes, errors }">
                  <b-form-select
                      name="stav"
                      v-model="formData.stav"
                      :options="lists.stav"
                      class="form-select form-select-sm"
                      size="sm"
                      :title="errors[0]"
                      :state="getState(classes, formData.stav)"
                      :disabled="formData.podepsana"
                      @change="changeStav"
                  />
                </ValidationProvider>
              </b-form-group>

              <b-form-group class="w-50 pl-3 text-white" label="3 ">
                <div>
                  <span class="text-black">Poslední změna {{formData.stavDate ? getDate(formData.stavDate) : '---'}}</span>
                </div>
              </b-form-group>
            </div>

            <div class="d-flex pt-1">
              <b-form-group class="label-1 w-35 text-nowrap" label="Předpokládaná cena (bez DPH)" label-for="predpokladanaCena">
                <ValidationProvider rules="required|double|min_value:0" name="'Předpokládaná cena (bez DPH)'" v-slot="{ classes, errors }">
                  <vue-numeric
                      id="predpokladanaCena"
                      currency-symbol-position="suffix"
                      thousand-separator=" "
                      decimal-separator=","
                      :precision="2"
                      currency="Kč"
                      class="form-control form-control-sm"
                      :class="getClassByValidatorState(classes)"
                      v-model="formData.predpokladanaCena"
                      :disabled="formData.podepsana"
                      :title="errors[0]"
                  />
                </ValidationProvider>
              </b-form-group>
              <b-form-group class="label-1 w-150px pl-4" label="Pravděpodobnost" label-for="pravdepodobnost">
                <ValidationProvider rules="required" name="'Pravděpodobnost'" mode="lazy" v-slot="{ classes, errors }">
                  <b-form-select
                      name="pravdepodobnost"
                      v-model="formData.pravdepodobnost"
                      :options="lists.pravdepodobnost"
                      class="form-select form-select-sm"
                      size="sm"
                      :disabled="formData.podepsana"
                      :title="errors[0]"
                      :state="getState(classes, formData.pravdepodobnost)"
                  />
                </ValidationProvider>
              </b-form-group>
              <b-form-group class="label-1 w-150px ml-3 color-white pt-2px" label="2" label-for="doReportuOR">
                <b-form-checkbox
                    id="doReportuOR"
                    v-model="formData.doReportuOR"
                    class="d-inline pr-3 color-black font-weight-normal text-nowrap"
                >
                  zahrnout do Reportu OŘ
                </b-form-checkbox>
              </b-form-group>
            </div>

            <div v-if="isStavProhra" class="d-flex pt-1">
              <b-form-group class="label-1 w-50" label="Kategorie prohry" label-for="prohryCategory">
                <ValidationProvider rules="required" name="'Kategorie prohry'" mode="lazy" v-slot="{ classes, errors }">
                  <b-form-select
                      name="prohryCategory"
                      v-model="formData.prohryCategory"
                      :options="lists.prohryCategories"
                      class="form-select form-select-sm"
                      size="sm"
                      :title="errors[0]"
                      :state="getState(classes, formData.prohryCategory)"
                  />
                </ValidationProvider>
              </b-form-group>
              <b-form-group class="label-1 w-50 pl-3" label="Důvod prohry" label-for="duvodProhry">
                <ValidationProvider rules="required" name="'Důvod prohry'" v-slot="{ classes, errors }">
                  <b-form-input
                      id="duvodProhry"
                      name="duvodProhry"
                      v-model="formData.duvodProhry"
                      class="form-control form-control-sm"
                      :title="errors[0]"
                      :state="getState(classes)"
                  />
                </ValidationProvider>
              </b-form-group>
            </div>
            <div v-else-if="isStavZrusheno" class="d-flex pt-1">
              <b-form-group class="label-1 w-100" label="Důvod nutností mazaní OP ze systému" label-for="duvodProhry">
                <ValidationProvider rules="required" name="'Důvod nutností mazaní OP ze systému'" v-slot="{ classes, errors }">
                  <b-form-input
                      id="duvodProhry"
                      name="duvodProhry"
                      v-model="formData.duvodProhry"
                      class="form-control form-control-sm"
                      :title="errors[0]"
                      :state="getState(classes)"
                  />
                </ValidationProvider>
              </b-form-group>
            </div>

            <hr class="mt-3 mb-3">

            <div class="d-flex w-100">
              <b-form-group class="label-1 w-50" label="Naplánovaná aktivita" label-for="naplanovanaAktivitaDate">
                <b-form-datepicker
                    id="naplanovanaAktivitaDate-datepicker"
                    v-model="formData.naplanovanaAktivitaDate"
                    :start-weekday="1"
                    size="sm"
                    locale="cs"
                    :disabled="!!formData.activityDone"
                    dropleft
                />
              </b-form-group>

              <b-form-group class="label-1 w-50 pl-3" label="Poslední aktivita" label-for="posledniAktivitaDate">
                <b-form-datepicker
                    id="posledniAktivitaDate-datepicker"
                    v-model="formData.posledniAktivitaDate"
                    :start-weekday="1"
                    size="sm"
                    locale="cs"
                    disabled
                />
              </b-form-group>
            </div>

            <template v-if="formData.naplanovanaAktivitaDate">
              <div class="d-flex pt-1">
                <div class="w-110px">
                  <b-form-group class="label-1" label="Čas" label-for="timeForActivity">
                    <vue-timepicker
                        :minute-interval="5"
                        v-model="formData.timeForActivity"
                        auto-scroll
                        class="time-picker-activity"
                        input-width="110px"
                        :hour-range="[[7,21]]"
                        hide-disabled-items
                        close-on-complete
                        input-class="form-control-sm input-timepicker"
                        :disabled="formData.activityDone"
                    />
                  </b-form-group>
                  <b-form-group class="label-1" label="Typ aktivity" label-for="activityType">
                    <ValidationProvider :rules="{required: formData.naplanovanaAktivitaDate}" name="'Typ aktivity'" v-slot="{ classes, errors }">
                      <b-form-select
                          name="activityType"
                          v-model="formData.activityType"
                          :options="lists.activityType"
                          class="form-select form-select-sm"
                          size="sm"
                          :disabled="formData.activityDone"
                          :title="errors[0]"
                          :state="getState(classes, formData.prohryCategory)"
                      />
                    </ValidationProvider>
                  </b-form-group>
                </div>
                <div class="w-100 pl-3">
                  <b-form-group class="label-1" label="Popis aktivity" label-for="popisAktivity">
                    <b-form-textarea
                        id="popisAktivity"
                        v-model="formData.popisAktivity"
                        placeholder=""
                        rows="4"
                        max-rows="4"
                        class="fs-14px"
                        :disabled="formData.activityDone"
                    />
                  </b-form-group>
                  <div class="d-flex justify-content-between">
                    <b-form-checkbox
                        id="activityDone"
                        v-model="formData.activityDone"
                        class="d-inline pr-3 dark-blue"
                        @change="changeActivityDone"
                    >
                      Splněno
                    </b-form-checkbox>
                    <div>
                      <b-icon-x-circle
                          @click="clearActivity"
                          class="cursor-pointer w-18px d-inline dark-red circle-icon"
                          :class="[formData.naplanovanaAktivitaDate && !formData.activityDone ? 'filtered' : 'cursor-not-allowed']"
                          font-scale="1.8"
                          title="Smazat naplánovanou aktivitu"
                      />
                      <a href="#"
                         @click="clearActivity"
                         class="dark-red pl-1 v-align-super text-decoration-none"
                         title="Smazat naplánovanou aktivitu"
                      >
                        <span class="color-inherit">Smazat naplánovanou aktivitu</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </template>

            <div class="label-1-nofont fs-18px pt-3 pb-1">
              Aktuální CN a související soubory
            </div>
            <VueFileAgent
                ref="vueFileAgent"
                :theme="'list'"
                :multiple="true"
                :deletable="true"
                :accept="'image/*,.pdf,.doc,.docx,.xls,.xlsx'"
                :maxSize="mazSize"
                :maxFiles="maxFiles"
                :helpText="'Vyberte nebo přetáhněte soubory do tohoto pole'"
                :errorText="{
                          type: 'Invalid file type. Only Word, Excel and images allowed',
                          size: 'Velikost souborů by neměla přesáhnout ' + mazSize,
                        }"
                :linkable="true"
                :editable="true"
                @select="filesSelected($event)"
                @beforedelete="onBeforeDelete($event)"
                @rename="filenameChanged($event)"
                v-model="formData.filesObchodnihoPripadu"
            />
            <div class="pt-2 text-left">
              <b-icon-paperclip variant="info" font-scale="1.5"/>
              <span class="fs-17px">Přidané soubory: <b>{{ formData.filesObchodnihoPripadu.length }} z {{
                  maxFiles
                }}</b></span>
            </div>

            <div class="d-flex justify-content-center w-100>">
              <b-button @click="uploadFiles()" type="submit" class="mt-4 mb-2" variant="primary">Uložit data a zavřít</b-button>
            </div>
          </b-form>
        </ValidationObserver>

      </div>
    </b-overlay>
  </b-modal>
</template>

<script>
import axios from "axios";
import commonMixin from "@/mixins/common.mixin.ts";
import VueTimepicker from 'vue2-timepicker';
import moment from "moment";
import {mapGetters} from "vuex";
import {STAV_CODES} from "@/constants/system.constants";
import {PRAVDEPODOBNOST_LIST} from "@/constants/form.constants";

const TOKEN_KEY = 'jwt-token';
const today = new Date();
today.setHours(12, 0, 0, 0);

// TODO: add start validator to uploadFiles() !!!!!!!!!

export default {
  components: {VueTimepicker},
  computed: {
    isHaveAccess() {
      return this.$store.getters.isStavObchodnihoPripaduAllowed;
    },
    ...mapGetters(['getUserNamesAndTitles']),
    isStavProhra() {
      return STAV_CODES.PROHRA === this.formData.stav;
    },
    isStavZrusheno() {
      return STAV_CODES.ZRUSENO === this.formData.stav;
    },
  },
  mixins: [commonMixin],
  props: {
    id: { type: String },
    kodZakazky: { type: String },
    show: { type: Boolean },
  },
  data() {
    return {
      isLoading: false,
      showLocal: false,
      mazSize: '10MB',
      maxFiles: 10,
      formData: {
        stav: 0,
        stavDate: null,
        kodZakazky: null,
        predpokladanaCena: null,
        pravdepodobnost: null,
        posledniAktivitaDate: null,
        naplanovanaAktivitaDate: null,
        prohryCategory: null,
        duvodProhry: null,
        filesObchodnihoPripadu: [],
        timeForActivity: null,
        popisAktivity: null,
        activityType: null,
        activityDone: false,
        podepsana: false,
        doReportuOR: false,
      },
      lists: {
        stav: [],
        pravdepodobnost: PRAVDEPODOBNOST_LIST,
        prohryCategories: [],
        activityType: [],
      },
      fileRecords: [],
      urlApiUploadFilesObchodnihoPripadu: `${process.env.VUE_APP_BACKEND_URL}/obchod/uploadFilesObchodnihoPripadu`,
      urlApiDownloadFilesObchodnihoPripadu: `${process.env.VUE_APP_BACKEND_URL}/obchod/obchodniPripad/download`,
      urlApiDeleteFileObchodnihoPripadu: `${process.env.VUE_APP_BACKEND_URL}/obchod/obchodniPripad/delete`,
      fileRecordsForUpload: [],
    }
  },
  methods: {
    async getConstants() {
      try {
        const dataConst = await this.getConstantsFromServer(
            ['STAV_STATUSES', 'PROHRY_CATEGORIES', 'STAV_ACTIVITY_TYPE']
        ) || {};
        this.lists.stav = dataConst.STAV_STATUSES;
        this.lists.prohryCategories = dataConst.PROHRY_CATEGORIES;
        this.lists.activityType = dataConst.STAV_ACTIVITY_TYPE;
      } catch (e) {
        console.error('getConstants', e);
      }
    },
    async getSavedData() {
      if (!this.id) {
        return;
      }

      const url = `/obchod/getObchodniPripadInfo`;
      const { data: {data} } = await axios.get(url, { params: { id: this.id } });
      const info = data?.info || {};
      this.formData = {...this.formData, ...info};
      this.formData?.filesObchodnihoPripadu?.forEach(file => {
        file.url = `${this.urlApiDownloadFilesObchodnihoPripadu}/${file.systemName}`;
        file.urlDelete = `${this.urlApiDeleteFileObchodnihoPripadu}/${file.systemName}`;
      })
      if (!this.formData.podepsana) {
        this.lists.stav = this.lists.stav.filter(stav => stav.value !== STAV_CODES.VYHRA);
      }
    },
    async onSubmit(close = true) {
      try {
        const url = `/obchod/saveStavObchodnihoPripadu`;
        const {data} = await axios.post(url, {...this.formData, id: this.id});
        this.$snotify[data.status](data.data.message);
        this.$emit('form-saved');
        if (data.status === 'success' && close) {
          this.$emit('close-modal');
        }
        await this.$store.dispatch('getActivitiesCount');
      } catch (e) {
        console.error('Change Stav obchodního případu', e.response?.data);
        this.$snotify['error'](e.response?.data?.message || 'Internal Server Error');
      }
    },
    changeStav() {
      this.formData.stavDate = today;
      if (!this.isStavProhra) {
        this.formData.prohryCategory = null;
        this.formData.duvodProhry = null;
      }
    },
    filesUploaded() {
      this.$emit('close-modal');
    },
    getDate(date, format = 'DD.MM.YYYY') {
      return moment(date).format(format);
    },
    getState({invalid, validated}, skip = false) {
      if (skip) return null;
      return validated ? !invalid : null;
    },
    clearActivity() {
      if (this.formData.activityDone) return;

      this.formData.naplanovanaAktivitaDate = null;
      this.formData.timeForActivity = { "HH": "", "mm": "" };
      this.formData.activityType = null;
      this.formData.popisAktivity = null;
    },

    async saveChangeStatusActivityMsg() {
      try {
        if (!this.formData.activityDone) return;

        const url = `/obchod/saveChangeStatusActivityMsg`;
        await axios.post(url, {id: this.id});
      } catch (e) {
        console.error('Save activity info', e.response?.data);
      }
    },

    async changeActivityDone() {
      this.formData.posledniAktivitaDate = today;
      await this.onSubmit(false);
      await this.saveChangeStatusActivityMsg();
      await this.$store.dispatch('getActivitiesCount');
    },

    // Files
    uploadFiles: async function () {
      try {
        if (!this.fileRecordsForUpload.length) {
          return // this.onSubmit();
        }

        const uploadHeaders = this.getHeaders();
        const resultsOfUpload = await this.$refs.vueFileAgent.upload(this.urlApiUploadFilesObchodnihoPripadu, uploadHeaders, this.fileRecordsForUpload);
        resultsOfUpload.forEach(r => {
          const {data} = r;
          this.$snotify[data.status](data.data.message);
        });
        this.fileRecordsForUpload = [];
        await this.onSubmit();
        this.filesUploaded();
      } catch (e) {
        console.log('uploadFiles', e.response?.data);
        this.$snotify['error'](e.response?.data?.message || 'Internal Server Error');
      }
    },
    filesSelected: function (fileRecordsNewlySelected) {
      let validFileRecords = fileRecordsNewlySelected.filter((fileRecord) => !fileRecord.error);
      this.fileRecordsForUpload = this.fileRecordsForUpload.concat(validFileRecords);
    },
    onBeforeDelete: async function (fileRecord) {
      let i = this.fileRecordsForUpload.indexOf(fileRecord);
      if (i !== -1) {
        this.fileRecordsForUpload.splice(i, 1);
        let k = this.formData.filesObchodnihoPripadu.indexOf(fileRecord);
        if (k !== -1) this.formData.filesObchodnihoPripadu.splice(k, 1);
      } else {
        if (confirm('Opravdu chcete tento soubor smazat?')) {
          await axios.get(fileRecord.urlDelete, {params: {kod: this.kodZakazky, id: this.id}});
          this.$snotify.success('Soubor byl smazán');
          let i = this.formData.filesObchodnihoPripadu.indexOf(fileRecord);
          if (i !== -1) {
            this.formData.filesObchodnihoPripadu.splice(i, 1);
          }
        }
      }
    },
    async filenameChanged(fileData){
        try {
          const newFileName = fileData.name();
          const editedFile = this.formData.filesObchodnihoPripadu?.filter(file => file.systemName === fileData.systemName) || [];
          const systemName = editedFile.length ? editedFile[0]?.systemName : null;
          if (!newFileName || !systemName) return;

          const {data} = await axios.post(fileData.url(), {params: {id: this.id, systemName, newFileName}});
          this.$snotify[data.status](data.data.message);
        } catch (e) {
          console.log('filenameChanged', e.response?.data);
          this.$snotify['error'](e.response?.data?.message || 'Internal Server Error');
        }
    },
    getHeaders: function () {
      return {
        'Authorization': `Bearer ${ localStorage.getItem(TOKEN_KEY) }`,
        'kod': this.kodZakazky,
        stavid: this.id,
      };
    },
  },
  async mounted() {
    this.isLoading = true;
    await this.getConstants();
    await this.getSavedData();
    this.isLoading = false;
    this.showLocal = this.show;
  },
}
</script>

<style scoped>
  h6 {
    font-weight: 500;
    font-size: 28px;
  }
  .time-picker-activity {
    z-index: 999;
  }
</style>