<template>
  <div id="app" class="vh-100 position-relative">
    <div id="content-wrap">
      <Snowf
          v-if="isChristmasTime"
          :amount="30"
          :size="5"
          :speed="1.4"
          :wind="0"
          :opacity="0.8"
          :swing="1"
          :image="'/snow.gif'"
          :zIndex="-1"
          :resize="true"
          color="#fff"
      />
      <NavBar
          v-if="isLoggedIn"
          :routeName="$route.name"
          @show-co-je-noveho="showCoJeNovehoModal"
          @show-nesplnene-aktivity-po-terminu="showNesplneneAktivityPoTerminuModal"
          @show-plan-nejblizsich-aktivit="showPlanNejblizsichAktivitModal"
      />
      <router-view />
      <vue-snotify />
      <co-je-noveho
          v-if="isShowCoJeNovehoForm"
          :show="isShowCoJeNovehoForm"
          @close-modal="isShowCoJeNovehoForm = false"
      />
      <nesplnene-aktivity-po-terminu
          v-if="isNesplneneAktivityPoTerminuForm"
          :show="isNesplneneAktivityPoTerminuForm"
          @close-modal="isNesplneneAktivityPoTerminuForm = false"
      />
      <plan-nejblizsich-aktivit
          v-if="isShowPlanNejblizsichAktivitForm"
          :show="isShowPlanNejblizsichAktivitForm"
          @close-modal="isShowPlanNejblizsichAktivitForm = false"
      />
    </div>

    <app-modals />
    <Footer v-if="isLoggedIn" />
  </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import Footer from "@/components/Footer.vue";
import CoJeNoveho from "@/components/modals/CoJeNovehoForm.vue";
import NesplneneAktivityPoTerminu from "@/components/modals/NesplneneAktivityPoTerminu.vue";
import PlanNejblizsichAktivit from "@/components/modals/PlanNejblizsichAktivit.vue";
import AppModals from "@/components/modals/AppModals.vue";
import Snowf from 'vue-snowf';
import moment from "moment";

export default {
  components: {
    NesplneneAktivityPoTerminu,
    PlanNejblizsichAktivit,
    Footer,
    NavBar,
    CoJeNoveho,
    Snowf,
    AppModals,
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters.isAuthenticated;
    },
    isChristmasTime() {
      const month = moment().month() + 1;
      if (month !== 12 && month !== 1) return false;
      const day = moment().date();
      if (month === 12 && day < 19) return false;
      else if (month === 1 && day > 7) return false;
      return true;
    },
  },
  data() {
    return {
      isShowCoJeNovehoForm: false,
      isShowPlanNejblizsichAktivitForm: false,
      isNesplneneAktivityPoTerminuForm: false,
    }
  },
  methods: {
    showCoJeNovehoModal() {
      this.isShowCoJeNovehoForm = true;
    },
    showNesplneneAktivityPoTerminuModal() {
      this.isNesplneneAktivityPoTerminuForm = true;
    },
    showPlanNejblizsichAktivitModal() {
      this.isShowPlanNejblizsichAktivitForm = true;
    },
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
