<template>
  <b-modal
      v-if="isHaveAccess"
      v-model="showLocal"
      scrollable centered hide-footer no-close-on-backdrop
      dialog-class="gdpr-form"
      @hidden="$emit('close-modal')"
  >
    <template #modal-title>
      <div class="text-center">
        <h6 class="text-nowrap w-100">Kontaktní údaje k obchodnímu případu č. {{ kodZakazky }}</h6>
      </div>
    </template>

    <b-overlay :show="isLoading" rounded="sm">
      <div class="modalWindow-w100" @click.stop>
        <ValidationObserver v-slot="{ handleSubmit }">
          <b-form @submit.stop.prevent="handleSubmit(saveGDPRForm)">
            <div v-for="(persona, indx) in personasInfo" :key="'persona_' + indx">
              <div>
                <span class="bold-7 fs-15px pr-3">{{
                    isBDorFirmOrVSObject && !indx ? 'Právnická osoba' : `Osoba č. ${indx + 1}`
                  }}</span>
                <b-icon-x-circle
                    v-if="indx"
                    class="cursor-pointer filtered fs-14px"
                    @click="removePerson(indx)"
                />
                <span v-if="indx" @click="removePerson(indx)" class="filtered cursor-pointer pl-1 fs-15px">smazat osobu</span>
              </div>
              <div class="d-flex pt-1 justify-content-between">
                <div class="text-left d-flex mt-2">
                    <label v-if="isShowTitle(indx)" for="titleBeforeName" class="text-nowrap mr-1 pt-label">
                      Tituly před jménem
                    </label>
                    <b-form-input
                        v-if="isShowTitle(indx)"
                        :id="'titleBeforeName' + indx"
                        type="text"
                        size="sm"
                        v-model.trim="persona.titleBeforeName"
                        class="w-75px mr-3"
                    />

                    <template v-if="isFirmaOrVSObject">
                      <div class="text-nowrap w-130px">
                        <label :for="!indx ? 'Název subjektu ' : 'Příjmení ' + (indx + 1)" class="pr-1">{{ !indx ? 'Název subjektu' : 'Příjmení' }}</label>
                        <label class="req">*</label>
                      </div>
                      <div class="d-flex w-100">
                        <ValidationProvider
                            rules="required|min:3"
                            :name="!indx ? 'Název subjektu ' : 'Příjmení ' + (indx + 1)"
                            v-slot="{ classes, errors }"
                        >
                          <b-form-input
                              :id="'lastName1_' + indx"
                              type="text"
                              size="sm"
                              v-model.trim="persona.lastName"
                              :class="[!indx ? 'w-220px' : '']"
                              :title="errors[0]"
                              :state="getState(classes)"
                          />
                        </ValidationProvider>
                        <div class="d-flex">
                          <div class="text-nowrap">
                            <label :for="!indx ? 'Právní forma ' : 'Jméno ' + (indx + 1)" class="pr-1 pl-3">{{ !indx ? 'Právní forma' : 'Jméno' }}</label>
                            <label class="req">*</label>
                          </div>
                          <ValidationProvider
                              rules="required|min:2"
                              :name="!indx ? 'Právní forma ' : 'Jméno ' + (indx + 1)"
                              v-slot="{ classes, errors }"
                          >
                            <b-form-input
                                :id="'name1_' + indx"
                                type="text"
                                size="sm"
                                v-model.trim="persona.name"
                                :class="[!indx ? 'w-200px' : '']"
                                :title="errors[0]"
                                :state="getState(classes)"
                            />
                          </ValidationProvider>
                          <img v-if="isFirmaOrVSObject && !indx"
                               id="aresGovCzImg"
                               class="icon-logo ml-1 mt-1"
                               src="@/assets/ares_gov_cz.png"
                               alt="ares.gov.cz"
                               @click="openAresLink(persona.lastName)"
                               v-b-tooltip.hover="{
                                title: 'ARES',
                                delay: { show: 200, hide: 200 },
                                variant: 'secondary'
                              }"
                          />
                        </div>
                      </div>
                    </template>

                    <template v-else>
                      <div class="d-flex text-nowrap">
                        <label :for="isBDObject && !indx ? 'Číslo domu (čísla domů) ' : 'Jméno ' + (indx + 1)" class="pr-1 pt-label">
                          {{isBDObject && !indx ? 'Číslo domu (čísla domů)' : 'Jméno'}}
                        </label>
                        <label class="req">*</label>
                      </div>
                      <div class="d-flex w-100 justify-content-between">
                        <ValidationProvider
                            :key="'name2_' + indx"
                            rules="required|min:3"
                            :name="isBDObject && !indx ? 'Číslo domu (čísla domů) ' : 'Jméno ' + (indx + 1)"
                            v-slot="{ classes, errors }"
                        >
                          <b-form-input
                              :id="'name2_' + indx"
                              type="text"
                              size="sm"
                              v-model.trim="persona.name"
                              :class="[isBDObject && !indx ? 'w-200px' : '']"
                              :title="errors[0]"
                              :state="getState(classes)"
                          />
                        </ValidationProvider>
                        <div class="d-flex">
                          <div class="text-nowrap">
                            <label :for="isBDObject && !indx ? 'Ulice ' : 'Příjmení ' + (indx + 1)" class="pl-3 pr-1 pt-label">
                              {{ isBDObject && !indx ? 'Ulice' : 'Příjmení' }}
                            </label>
                            <label class="req">*</label>
                          </div>
                          <ValidationProvider
                              :key="'lastName2_' + indx"
                              rules="required|min:3"
                              :name="isBDObject && !indx ? 'Ulice ' : 'Příjmení ' + (indx + 1)"
                              v-slot="{ classes, errors }"
                          >
                            <b-form-input
                                :id="'lastName2_' + indx"
                                type="text"
                                size="sm"
                                v-model.trim="persona.lastName"
                                :class="[isBDObject && !indx ? 'w-200px' : 'w-160px']"
                                :title="errors[0]"
                                :state="getState(classes)"
                            />
                          </ValidationProvider>
                        </div>
                      </div>
                    </template>

                    <template v-if="isShowTitle(indx)" class="text-left d-flex">
                      <label for="titleAfterName" class="pl-3 text-nowrap pt-label">Tituly za jménem</label>
                      <b-form-input
                          :id="'titleAfterName' + indx"
                          type="text"
                          size="sm"
                          v-model.trim="persona.titleAfterName"
                          class="d-inline-block w-75px"
                      />
                    </template>
                  </div>
              </div>

              <div class="d-flex">
                <div class="text-left pt-3 w-100 d-flex">
                    <div class="d-flex text-nowrap w-130px">
                      <label :for="'Telefonní číslo ' + (indx + 1)" class="pr-1 pt-label">Telefonní číslo</label>
                      <label class="req" :class="[persona.noInfoYet ? 'req-hide': '']">*</label>
                    </div>
                    <div class="d-flex">
                      <ValidationProvider
                          :rules="getValidatorRulesForPhone(persona)"
                          :name="'Telefonní číslo ' + (indx + 1)"
                          v-slot="{ classes, errors }"
                      >
                        <b-form-input
                            :id="'phone' + indx"
                            type="tel"
                            size="sm"
                            v-model.trim="persona.phone"
                            class="w-200px"
                            v-mask="'### ### ###'"
                            :title="errors[0]"
                            :state="getState(classes)"
                        />
                      </ValidationProvider>
                      <div class="pl-3">
                        <label :for="'Emailová adresa ' + (indx + 1)" class="pr-1 pt-label">
                          Emailová adresa
                        </label>
                        <label class="req" :class="[persona.noInfoYet ? 'text-white pr-0': '']">*</label>
                      </div>
                      <ValidationProvider
                          :rules="getValidatorRulesForEmail(persona)"
                          :name="'Emailová adresa ' + (indx + 1)"
                          v-slot="{ classes, errors }"
                      >
                        <b-form-input
                            :id="'email' + indx"
                            type="email"
                            size="sm"
                            class="w-280px"
                            v-model.trim="persona.email"
                            :title="errors[0]"
                            :state="getState(classes)"
                        />
                      </ValidationProvider>
                      <b-form-checkbox
                          :id="'noInfoYet' + indx"
                          v-model="persona.noInfoYet"
                          :name="'noInfoYet' + indx"
                          class="ml-4 red-check-box pt-label"
                          :class="[persona.noInfoYet ? 'text-red': '']"
                      >
                        zatím nevíme
                      </b-form-checkbox>
                    </div>
                  </div>
              </div>

              <div class="text-left pt-3 d-flex">
                <div class="d-flex text-nowrap w-130px">
                  <label :for="'datovaSchranka' + indx" class="pr-3 pt-label">Datová schránka</label>
                </div>
                <b-form-input
                    :id="'datovaSchranka' + indx"
                    type="tel"
                    size="sm"
                    v-model.trim="persona.datovaSchranka"
                    class="mw-200px w-200px"
                />
                <div class="d-flex w-100">
                  <div class="pl-3">
                    <label :for="'Poznámka ' + (indx + 1)" class="pr-2 pt-label">Poznámka</label>
                  </div>
                  <ValidationProvider
                      rules="max:300"
                      :name="'Poznámka ' + (indx + 1)"
                      class="w-100"
                      v-slot="{ classes, errors }"
                  >
                    <b-form-input
                        :id="'poznamka' + indx"
                        type="tel"
                        size="sm"
                        v-model.trim="persona.poznamka"
                        :title="errors[0]"
                        :state="getState(classes)"
                    />
                  </ValidationProvider>
                </div>
              </div>

              <div v-if="isRDObject || indx" class="d-flex pt-2">
                <div class="text-left pt-1 w-100 d-flex">
                  <label :for="'Rodné číslo ' + (indx + 1)" class="text-nowrap pt-label">Rodné číslo</label>
                  <ValidationProvider
                      :rules="getRodneCisloRules(persona.rodneCislo)"
                      :name="'Rodné číslo ' + (indx + 1)"
                      v-slot="{ classes, errors }"
                  >
                    <b-form-input
                        :id="'rodneCislo' + indx"
                        type="text"
                        size="sm"
                        v-model.trim="persona.rodneCislo"
                        class="w-130px mr-4"
                        v-mask="'######/####'"
                        @change="inputRondeCislo(persona.rodneCislo, indx)"
                        :title="errors[0]"
                        :state="getState(classes)"
                    />
                  </ValidationProvider>

                  <label :for="'Datum narození ' + (indx + 1)" class="text-nowrap pt-label">Datum narození</label>
                  <ValidationProvider
                      :rules="getDateOfBirthRules(persona.dateOfBirth)"
                      :name="'Datum narození ' + (indx + 1)"
                      v-slot="{ classes, errors }"
                  >
                    <b-form-input
                        :id="'dateOfBirth' + indx"
                        type="text"
                        size="sm"
                        v-model.trim="persona.dateOfBirth"
                        class="w-120px"
                        v-mask="'##.##.####'"
                        placeholder="dd.mm.yyyy"
                        :title="errors[0]"
                        :state="getState(classes)"
                    />
                  </ValidationProvider>
                </div>
              </div>

              <div  class="pt-3 d-flex">
                <u v-if="isBDorFirmOrVSObject && !indx" class="fs-15px color-grey">Se sídlem:</u>
                <u v-else-if="(isRDObject && !indx) || indx" class="fs-15px color-grey">Adresa trvalého bydliště:</u>
                <b-form-checkbox
                    :id="'sameAsInstallationAddress' + indx"
                    v-model="persona.addressSameAsAddressInstallation"
                    :name="'sameAsInstallationAddress' + indx"
                    @change="selectSameInstallAddress(indx)"
                    class="ml-4"
                >
                  je stejná s adresou instalace
                </b-form-checkbox>
              </div>
              <div class="d-flex w-100 pt-2">
                <div class="d-flex w-100">
                  <label :for="'ulice' + indx" class="pr-2 pt-label">Ulice</label>
                  <b-form-input
                      :id="'ulice' + indx"
                      type="text"
                      size="sm"
                      v-model.trim="persona.addressMain.ulice"
                      :disabled="persona.addressSameAsAddressInstallation"
                      class="w-210px"
                  />

                  <label :for="'cislaDomu' + indx" class="pr-0 text-nowrap pl-3 pt-label">č.p.</label>
                  <b-form-input
                      :id="'cislaDomu' + indx"
                      type="text"
                      size="sm"
                      v-model.trim="persona.addressMain.cislaDomu"
                      :disabled="persona.addressSameAsAddressInstallation"
                      class="w-60px"
                  />

                  <label :for="'cisloOrientacni' + indx" class="pr-0 text-nowrap pl-3 pt-label">č.o.</label>
                  <b-form-input
                      :id="'cisloOrientacni' + indx"
                      type="text"
                      size="sm"
                      v-model.trim="persona.addressMain.cisloOrientacni"
                      :disabled="persona.addressSameAsAddressInstallation"
                      class="w-60px"
                  />
                  <span class="pl-1">,</span>

                  <label :for="'zip' + indx" class="pr-2 pl-3 pt-label">PSČ</label>
                  <b-form-input
                      :id="'zip' + indx"
                      type="text"
                      size="sm"
                      v-mask="'### ##'"
                      v-model.trim="persona.addressMain.zip"
                      :disabled="persona.addressSameAsAddressInstallation"
                      class="pr-2 w-70px"
                  />

                  <label :for="'mesto' + indx" class="pr-2 pl-3 text-nowrap pt-label">město (obec)</label>
                  <b-form-input
                      :id="'mesto' + indx"
                      type="text"
                      size="sm"
                      v-model.trim="persona.addressMain.mesto"
                      :disabled="persona.addressSameAsAddressInstallation"
                      class="col"
                  />
                </div>
              </div>

              <div v-if="!persona.showAddressPostal" class="pt-3 fs-15px">
                <b-icon-plus-circle
                    @click="showKorespondenciAdresu(indx)"
                    class="cursor-pointer fs-14px custom-link-grey d-inline"
                />
                <span @click="showKorespondenciAdresu(indx)" class="custom-link-grey color-grey pl-1">přidat korespondenční adresu</span>
              </div>
              <div v-else class="pt-1">
                <div><hr class="mb-2" /></div>
                <div>
                  <span class="pr-3 text-nowrap pt-label fs-15px color-grey"><u>Adresa korespondenční:</u></span>
                  <b-icon-x-circle
                      class="cursor-pointer filtered fs-14px d-inline"
                      @click="resetPostalAdress(indx)"
                  />
                  <span @click="resetPostalAdress(indx)" class="filtered cursor-pointer pl-1 fs-15px">smazat</span>
                  <b-form-checkbox
                      :id="'sameCorrespondenceAsInstallationAddress' + indx"
                      v-model="persona.addressPostalSameAsAddressInstallation"
                      :name="'sameCorrespondenceAsInstallationAddress' + indx"
                      @change="selectSamePostalAddress(indx)"
                      class="ml-4 d-inline"
                  >
                    je stejná s adresou instalace
                  </b-form-checkbox>
                </div>
                <div class="d-flex pt-2">
                  <label :for="'uliceKoresp' + indx" class="pr-2 pt-label">Ulice</label>
                  <b-form-input
                      :id="'uliceKoresp' + indx"
                      type="text"
                      size="sm"
                      v-model.trim="persona.addressPostal.ulice"
                      :disabled="persona.addressPostalSameAsAddressInstallation"
                      class="w-210px"
                  />

                  <label :for="'cislaDomuKoresp' + indx" class="pr-0 text-nowrap pl-3 pt-label">č.p.</label>
                  <b-form-input
                      :id="'cislaDomuKoresp' + indx"
                      type="text"
                      size="sm"
                      v-model.trim="persona.addressPostal.cislaDomu"
                      :disabled="persona.addressPostalSameAsAddressInstallation"
                      class="w-60px"
                  />

                  <label :for="'cisloOrientacniKoresp' + indx" class="pr-0 text-nowrap pl-3 pt-label">č.o.</label>
                  <b-form-input
                      :id="'cisloOrientacniKoresp' + indx"
                      type="text"
                      size="sm"
                      v-model.trim="persona.addressPostal.cisloOrientacni"
                      :disabled="persona.addressPostalSameAsAddressInstallation"
                      class="w-60px"
                  />
                  <span class="pl-1">,</span>

                  <label :for="'zipKoresp' + indx" class="pr-2 pl-3 pt-label">PSČ</label>
                  <b-form-input
                      :id="'zipKoresp' + indx"
                      type="text"
                      size="sm"
                      v-mask="'### ##'"
                      v-model.trim="persona.addressPostal.zip"
                      :disabled="persona.addressPostalSameAsAddressInstallation"
                      class="pr-2 w-70px"
                  />

                  <label :for="'mestoKoresp' + indx" class="pr-2 pl-3 text-nowrap pt-label">město (obec)</label>
                  <b-form-input
                      :id="'mestoKoresp' + indx"
                      type="text"
                      size="sm"
                      v-model.trim="persona.addressPostal.mesto"
                      :disabled="persona.addressPostalSameAsAddressInstallation"
                      class="col"
                  />
                </div>
                <div><hr /></div>
              </div>

              <div v-if="!indx" class="d-flex pt-2">
                <div class="text-left pt-1 w-100 d-flex">
                  <label for="ICO" class="text-nowrap pt-label">IČO</label>
                  <b-form-input
                      id="ICO"
                      type="text"
                      size="sm"
                      v-model.trim="ICO"
                      class="w-100px mr-4"
                  />

                  <label for="DIC" class="pt-label">DIČ</label>
                  <b-form-input
                      :id="'DIC' + indx"
                      type="text"
                      size="sm"
                      v-model.trim="DIC"
                      class="w-100px mr-4"
                  />

                  <label :for="'Bankovní spojení' + (indx + 1)" class="text-nowrap pt-label">
                    Bankovní spojení
                  </label>
                  <ValidationProvider
                      :rules="getValidatorRulesForBankovniSpojeni"
                      :name="'Bankovní spojení' + (indx + 1)"
                      v-slot="{ classes, errors }"
                  >
                    <b-form-input
                        id="bankovniSpojeni"
                        name="bankovniSpojeni"
                        v-model.trim="bankovniSpojeni"
                        @input="checkInputBankovniSpojeni()"
                        size="sm"
                        class="w-190px"
                        :title="errors[0]"
                        :state="getState(classes) && !!bankovniSpojeni"
                    />
                    <span class="errMsg fs-13px" v-html="errors[0]"></span>
                  </ValidationProvider>
                  <span v-if="showBankovniSpojeniError" class="pl-2 text-nowrap pt-label text-red">nejde identifikovat banku</span>
                  <span v-else
                        id="bankovniSpojeniName"
                        class="pl-2 text-nowrap pt-label color-grey mnogotochie"
                  >
                    <b-tooltip target="bankovniSpojeniName" variant="secondary">{{getBankName()}}</b-tooltip>
                    {{getBankName()}}
                  </span>
                </div>
              </div>

              <div v-if="isFirmaOrVSObject && !indx" class="pt-3 d-flex">
                <label :for="'spisovaZnacka' + indx" class="fs-15px text-nowrap pr-2 pt-label">Spisová značka</label>
                <b-form-input
                    :id="'spisovaZnacka' + indx"
                    type="text"
                    size="sm"
                    v-model.trim="spisovaZnacka"
                    class="w-100"
                />
              </div>

              <div class="pt-3 fs-15px d-flex w-100 justify-content-between">
                <div class="pr-2">
                  <b-form-checkbox
                      v-if="!indx"
                      :id="'investor' + indx"
                      v-model="persona.investor"
                      name="investor"
                      class="pr-3 d-inline-block"
                  >
                    investor
                  </b-form-checkbox>

                  <b-form-checkbox
                      v-if="!indx"
                      :id="'houseOwner' + indx"
                      v-model="persona.houseOwner"
                      name="houseOwner"
                      class="pr-3 d-inline-block"
                  >
                    majitel objektu
                  </b-form-checkbox>

                  <div v-if="isRDObject" class="pr-3 d-inline-block">
                    (
                    <b-form-checkbox
                        :id="'SJM' + indx"
                        v-model="persona.SJM"
                        name="SJM"
                        class="d-inline-block"
                    >
                      SJM
                    </b-form-checkbox>
                    )
                  </div>

                  <template v-if="indx">
                    <b-form-checkbox
                        :id="'osobaZastupujici' + indx"
                        v-model="persona.osobaZastupujici"
                        name="osobaZastupujici"
                        class="pr-3 d-inline-block"
                    >
                      osoba zastupující
                    </b-form-checkbox>
                  </template>

                  <template v-if="indx && isBDorFirmOrVSObject">
                    <b-form-checkbox
                        :id="'kontaktniOsoba' + indx"
                        v-model="persona.kontaktniOsoba"
                        name="kontaktniOsoba"
                        class="pr-3 d-inline-block"
                    >
                      kontaktní osoba
                    </b-form-checkbox>
                  </template>

                  <template v-if="indx && isBDorFirmOrVSObject">
                    <b-form-checkbox
                        :id="'jednatel' + indx"
                        v-model="persona.jednatel"
                        name="jednatel"
                        class="pr-3 d-inline-block"
                    >
                      jednatel
                    </b-form-checkbox>
                    <b-form-checkbox
                        :id="'spravceObjektu' + indx"
                        v-model="persona.spravceObjektu"
                        name="spravceObjektu"
                        class="pr-3 d-inline-block"
                    >
                      správce objektu
                    </b-form-checkbox>
                  </template>

                  <b-form-checkbox
                      :id="'electricityMeterOwner' + indx"
                      v-model="persona.electricityMeterOwner"
                      name="electricityMeterOwner"
                      class="d-inline-block pr-3"
                  >
                    majitel OM
                  </b-form-checkbox>

                  <template v-if="indx && isBDorFirmOrVSObject">
                    <b-form-checkbox
                        :id="'energetik' + indx"
                        v-model="persona.energetik"
                        name="energetik"
                        class="pr-3 d-inline-block"
                    >
                      energetik
                    </b-form-checkbox>
                  </template>
                </div>
                <div v-if="isShowGenerateGDPRlink(persona, indx)">
                  <a href="#" @click="generateGDPR(persona, indx)">GDPR (nepodepsáno)
                    <b-icon-download class="pl-2" variant="info" font-scale="1.5" />
                  </a>
                </div>
              </div>
              <hr class="hr-dashed-black">
            </div>

            <div v-if="personasInfo && personasInfo.length < 5" class="text-left font-weight-bold pt-0">
              <b-icon-plus-circle
                  @click="addPersona()"
                  variant="primary"
                  class="cursor-pointer fs-14px"
              />
              <span class="pl-1">Přidat osobu</span>
            </div>

            <div class="d-flex justify-content-center w-100>">
              <b-button type="submit" class="mt-4 mb-2" variant="primary">Uložit data a zavřít</b-button>
            </div>
          </b-form>
        </ValidationObserver>
      </div>
    </b-overlay>
  </b-modal>
</template>


<script>
import axios from "axios";
import _ from "lodash";
import saveAs from 'save-as';
import commonMixin from "@/mixins/common.mixin.ts";
import {MIME_TYPES, OBJECT_TYPE} from "@/constants/form.constants";
import { BANK_CODES } from "@/constants/form.constants";

const addressEmpty = {
  cislaDomu: '',
  cisloOrientacni: '',
  mesto: '',
  ulice: '',
  zip: '',
};

export default {
  components: {},
  props: {
    id: {type: String},
    kodZakazky: { type: String },
    show: { type: Boolean },
  },
  mixins: [commonMixin],
  computed: {
    isHaveAccess() {
      return this.$store.getters.isGDPRRodinnePrislusniciAllowed || this.$store.getters.isGDPRServiceAllowed;
    },
    isBDObject() {
      return this.savedGDPRInfo?.object === OBJECT_TYPE.BD;
    },
    isFirmaOrVSObject() {
      return [OBJECT_TYPE.FIRM, OBJECT_TYPE.VS].includes(this.savedGDPRInfo?.object);
    },
    isBDorFirmOrVSObject() {
      return this.isBDObject || this.isFirmaOrVSObject;
    },
    isRDObject() {
      return this.savedGDPRInfo?.object === OBJECT_TYPE.RD;
    },
    getValidatorRulesForBankovniSpojeni() {
      return this.bankovniSpojeni ? 'required|bankovniSpojeni' : '';
    },
  },
  data() {
    const personaDefaultInfo = {
      lastName: '',
      name: '',
      phone: null,
      email: null,
      address: null,
      addressMain: {...addressEmpty},
      addressPostal: {...addressEmpty},
      titleBeforeName: '',
      titleAfterName: '',
      SJM: null,
      investor: true,
      houseOwner: true,
      jednatel: null,
      kontaktniOsoba: null,
      energetik: null,
      osobaZastupujici: null,
      spravceObjektu: null,
      electricityMeterOwner: null,
      addressSameAsAddressInstallation: false,
      addressPostalSameAsAddressInstallation: false,
      noInfoYet: false,
      showAddressPostal: false,
      rodneCislo: null,
      dateOfBirth: null,
      datovaSchranka: null,
      poznamka: null,
    };

    return {
      isLoading: false,
      showLocal: false,
      addressInstallation: null,
      personasInfo: [_.cloneDeep(personaDefaultInfo)],
      personaDefaultInfo,
      savedGDPRInfo: {},
      ICO: null,
      DIC: null,
      bankovniSpojeni: null,
      spisovaZnacka: null,
      showBankovniSpojeniError: false,
    }
  },
  methods: {
    getState({invalid, validated}, skip = false) {
      if (skip) return null;
      return validated ? !invalid : null;
    },
    getValidatorRulesForPhone(persona) {
      if (persona.noInfoYet && !persona.phone) {
        return '';
      } else if (persona.phone) {
        return 'required|min:11';
      }
      return 'required|min:11';
    },
    getValidatorRulesForEmail(persona) {
      if (persona.noInfoYet && !persona.email) {
        return '';
      } else if (persona.email) {
        return 'email';
      }
      return 'required|email';
    },
    setAddressFromGDPRInfo(indx) {
      const persona = {...this.personasInfo[indx]};
      persona['addressMain'] = {
        cislaDomu: this.savedGDPRInfo?.cislaDomu || null,
        cisloOrientacni: this.savedGDPRInfo?.cisloOrientacni || null,
        mesto: this.savedGDPRInfo?.cityInstallation || null,
        ulice: this.savedGDPRInfo?.addressInstallation || null,
        zip: this.savedGDPRInfo?.zip || null,
      }
      this.$set(this.personasInfo, indx, persona);
    },
    setPostalAddressFromGDPRInfo(indx) {
      const persona = {...this.personasInfo[indx]};
      persona['addressPostal'] = {
        cislaDomu: this.savedGDPRInfo?.cislaDomu || null,
        cisloOrientacni: this.savedGDPRInfo?.cisloOrientacni || null,
        mesto: this.savedGDPRInfo?.cityInstallation || null,
        ulice: this.savedGDPRInfo?.addressInstallation || null,
        zip: this.savedGDPRInfo?.zip || null,
      }
      this.$set(this.personasInfo, indx, persona);
    },
    selectSameInstallAddress(indx) {
      if (this.personasInfo[indx].addressSameAsAddressInstallation) {
        this.setAddressFromGDPRInfo(indx);
      }
    },
    selectSamePostalAddress(indx) {
      if (this.personasInfo[indx].addressPostalSameAsAddressInstallation) {
        this.setPostalAddressFromGDPRInfo(indx);
      }
    },
    async saveGDPRForm(isNotClose = false) {
      try {
        const url = `/obchod/saveGDPRForm`;
        const {data} = await axios.post(url, {
          personasInfo: this.personasInfo, id: this.id, ICO: this.ICO, DIC: this.DIC, bankovniSpojeni: this.bankovniSpojeni,
          spisovaZnacka: this.spisovaZnacka,
        });
        this.$snotify[data.status](data.data.message);
        if (data.status === 'success') {
          this.$emit('form-saved')
          if (!isNotClose) this.$emit('close-modal');
        }
      } catch (e) {
        console.error('saveGDPRForm', e.response?.data);
        this.$snotify['error'](e.response?.data?.message || 'Internal Server Error');
      }
    },
    async getSavedData() {
      try {
        const setClientName = (info) => {
          this.personasInfo[0].name = info.clientName;
          this.personasInfo[0].lastName = info.clientLastName;
        };

        const url = `/obchod/getGDPRFormInfo`;
        const {data: {data}} = await axios.get(url, {params: {id: this.id}});
        this.savedGDPRInfo = data?.info || {};
        this.addressInstallation = this.savedGDPRInfo?.addressInstallation;
        this.ICO = this.savedGDPRInfo?.ICO;
        this.DIC = this.savedGDPRInfo?.DIC;
        this.bankovniSpojeni = this.savedGDPRInfo?.bankovniSpojeni;
        this.showBankovniSpojeniError = !this.getBankName(this.bankovniSpojeni);
        this.spisovaZnacka = this.savedGDPRInfo?.spisovaZnacka;

        const savedPersonasInfo = _.get(this.savedGDPRInfo, 'obchod.personasInfo', []);
        if (savedPersonasInfo?.length) {
          this.personasInfo = savedPersonasInfo;
          this.personasInfo?.forEach((persona, indx) => {
            if (persona.addressSameAsAddressInstallation) {
              this.setAddressFromGDPRInfo(indx);
            }
            if (persona.addressPostalSameAsAddressInstallation) {
              this.setPostalAddressFromGDPRInfo(indx);
            }
            this.personasInfo[indx].addressMain = {...this.personaDefaultInfo.addressMain, ...this.personasInfo[indx]?.addressMain};

            if (this.personasInfo[indx]?.addressPostal) {
              this.personasInfo[indx].addressPostal = _.omit(this.personasInfo[indx]?.addressPostal, ['_id']);
              this.$set(this.personasInfo[indx], 'showAddressPostal', !_.isEqual(this.personaDefaultInfo.addressPostal, this.personasInfo[indx]?.addressPostal));
            } else {
              this.$set(this.personasInfo[indx], 'showAddressPostal', false);
            }

            this.personasInfo[indx].addressPostal = {...this.personaDefaultInfo.addressPostal, ...this.personasInfo[indx]?.addressPostal};
          })
        } else {
          setClientName(this.savedGDPRInfo);
        }
      } catch (e) {
        this.$snotify['error'](e.response?.data?.message || 'Internal Server Error');
        console.error('getSavedData', e.response?.data)
      }
    },
    addPersona() {
      if (this.personasInfo.length < 5) {
        this.personasInfo.push(_.cloneDeep(this.personaDefaultInfo));
      }
    },
    removePerson(index) {
      this.personasInfo.splice(index, 1);
    },
    async generateGDPR(persona, index) {
      try {
        await this.saveGDPRForm(true);
        const personaName = this.joinName(persona).replace(/[.,]/g, '').replace(/\s/g, '_');
        const fileName = `${this.kodZakazky}_${personaName}_GDPR_${index + 1}_(nepodepsáno).pdf`;

        const url = `/obchod/generateGDPR`;
        const {data} = await axios.post(url, {id: this.id, indx: index}, {responseType: "blob"});
        if (data?.type !== MIME_TYPES.pdf) {
          return this.$snotify['error']('GDPR se nepodařilo vygenerovat');
        }

        this.$snotify['success']('GDPR vygenerováno');
        saveAs(data, fileName);
      } catch (e) {
        console.error('generateGDPR', e.response?.data);
        this.$snotify['error'](e.response?.data?.message || 'Internal Server Error');
      }
    },
    isShowGenerateGDPRlink(persona, index) {
      if (this.isBDorFirmOrVSObject && !index) { return false; }
      return persona.name && persona.lastName;
    },
    isShowTitle(index) {
      return !(this.isBDorFirmOrVSObject && !index);
    },
    showKorespondenciAdresu(index) {
      this.$set(this.personasInfo[index], 'showAddressPostal', true);
    },
    resetPostalAdress(index) {
      this.personasInfo[index].showAddressPostal = false;
      this.$set(this.personasInfo[index], 'addressPostal', {...addressEmpty});
      this.personasInfo[index].addressPostalSameAsAddressInstallation = false;
    },
    getBankName() {
      if (!this.bankovniSpojeni) return '';

      const split = this.bankovniSpojeni.split('/');
      const code = _.get(split, '[1]', '');
      return BANK_CODES[code] || '';
    },
    checkInputBankovniSpojeni() {
      this.showBankovniSpojeniError = !this.getBankName();
    },
    inputRondeCislo(rodneCislo, index) {
      const regexPattern = /^\d{6}\/\d{4}$/;
      if (regexPattern.test(rodneCislo)) {
        this.personasInfo[index].dateOfBirth = this.getDateOfBirthFromRodneCislo(rodneCislo);
      }
    },
    getDateOfBirthRules(dateOfBirth) {
      return dateOfBirth ? 'required|dateOfBirth' : '';
    },
    getRodneCisloRules(rodneCislo) {
      return rodneCislo ? 'required|rodneCislo' : '';
    },
    openAresLink(firmName) {
      const addQuery = firmName ? `?obchodniJmeno=${firmName}` : '';
      window.open(`https://ares.gov.cz/ekonomicke-subjekty${addQuery}`, '_blank', 'noopener,noreferrer');
    },
  },
  async mounted() {
    this.isLoading = true;
    await this.getSavedData();
    this.isLoading = false;
    this.showLocal = this.show;
  },
}
</script>

<style scoped>
  label {
    padding-right: 10px;
    font-size: 15px;
  }
  p {
    font-weight: bold;
    text-align: left;
    margin-top: 10px;
    margin-bottom: 5px;
    font-size: 15px;
  }
  .req {
    color: darkred;
    font-size: 17px
  }
</style>
