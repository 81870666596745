<template>
  <b-modal
      v-if="isHaveAccess"
      v-model="showLocal"
      scrollable centered hide-footer no-close-on-backdrop
      dialog-class="calendar-form"
      @hidden="$emit('close-modal')"
  >
    <template #modal-title>
      <div class="text-center">
        <h6 class="text-nowrap w-100">Plán nejbližších aktivit</h6>
      </div>
    </template>

    <b-overlay :show="isLoading" rounded="sm">
      <div class="modalWindow-w100" @click.stop>
        <div v-for="(activity, indx) in items" :key="indx + '_activity'">
          <aktivity
              :indx="indx"
              :activity="activity"
              :obchodniZastupceListById="{}"
              :activityTypeCodes="lists.activityTypeCodes"
              :showDate="true"
              @show-stav-obchodniho-pripadu-form="openStavObchodnihoPripaduModal"
              @show-gdpr-form="openGPPRModal"
              @show-poznamky-k-obchodnimu-pripadu="openPoznamkyKobchodnimuPripaduModal"
              @form-saved="formSaved"
          />
        </div>
      </div>

      <stav-obchodniho-pripadu-form
          v-if="showStavObchodnihoPripaduForm && $store.getters.isStavObchodnihoPripaduAllowed"
          :id="selectedContractId"
          :kodZakazky="selectedKodZakazky"
          :show="showStavObchodnihoPripaduForm"
          @close-modal="showStavObchodnihoPripaduForm = false"
          @form-saved="formSaved"
      />
      <gpdr-form
          v-if="showGDPRForm && $store.getters.isGDPRRodinnePrislusniciAllowed"
          :id="selectedContractId"
          :kodZakazky="selectedKodZakazky"
          :show="showGDPRForm"
          @close-modal="showGDPRForm = false"
          @form-saved="formSaved"
      />
      <poznamky-k-obchodnimu-pripadu-form
          v-if="showPoznamkyKobchodnimuPripaduForm && $store.getters.isPoznamkyKobchodnimuPripaduAllowed"
          :id="selectedContractId"
          :kodZakazky="selectedKodZakazky"
          :show="showPoznamkyKobchodnimuPripaduForm"
          @close-modal="showPoznamkyKobchodnimuPripaduForm = false"
      />
    </b-overlay>
  </b-modal>
</template>

<script>
import commonMixin from "@/mixins/common.mixin.ts";
import {mapGetters} from "vuex";
import Aktivity from "@/components/Aktivity.vue";
import axios from "axios";
import _ from "lodash";
import moment from "moment";
import PoznamkyKObchodnimuPripaduForm from "@/components/modals/PoznamkyPripojit.vue";
import GpdrForm from "@/components/modals/GDPRForm.vue";
import StavObchodnihoPripaduForm from "@/components/modals/StavObchodnihoPripaduForm.vue";

export default {
  mixins: [commonMixin],
  components: {
    StavObchodnihoPripaduForm, GpdrForm, PoznamkyKObchodnimuPripaduForm,
    Aktivity
  },
  computed: {
    isHaveAccess() {
      return this.$store.getters.getUserNumberOZ;
    },
    ...mapGetters(['getUserNamesAndTitles']),
  },
  props: {
    show: { type: Boolean },
  },
  data() {
    return {
      isLoading: false,
      showLocal: false,
      showStavObchodnihoPripaduForm: false,
      showPoznamkyKobchodnimuPripaduForm: false,
      showGDPRForm: false,
      selectedContractId: null,
      selectedKodZakazky: null,
      items: [],
      lists: {
        activityTypeCodes: {},
      },
    }
  },
  async mounted() {
    this.isLoading = true;
    await this.getConstants();
    await this.getItems();
    this.isLoading = false;
    this.showLocal = this.show;
  },
  methods: {
    async getConstants() {
      try {
        const dataConst = await this.getConstantsFromServer(['STAV_ACTIVITY_TYPE_CODES']) || {};
        this.lists.activityTypeCodes = dataConst.STAV_ACTIVITY_TYPE_CODES;
      } catch (e) {
        console.error('getConstants', e);
      }
    },
    openStavObchodnihoPripaduModal(contractId, kodZakazky) {
      this.showStavObchodnihoPripaduForm = true;
      this.selectedContractId = contractId;
      this.selectedKodZakazky = kodZakazky;
    },
    openGPPRModal(contractId, kodZakazky) {
      this.showGDPRForm = true;
      this.selectedContractId = contractId;
      this.selectedKodZakazky = kodZakazky;
    },
    openPoznamkyKobchodnimuPripaduModal(contractId, kodZakazky) {
      this.showPoznamkyKobchodnimuPripaduForm = true;
      this.selectedContractId = contractId;
      this.selectedKodZakazky = kodZakazky;
    },
    getActionTitle(item) {
      const {kodZakazky, timeForActivity, activityType, clientLastName} = item;
      const activityTypeStr = _.get(this.lists.activityTypeCodes, activityType);
      return [this.getTimeActivityString(timeForActivity), activityTypeStr, clientLastName, kodZakazky].filter(el => el).join(' - ');
    },
    async getItems() {
      try {
        this.isLoading = true;
        const url = `/calendar/getNejblizsichAktivitDates`;
        const {data} = await axios.get(url);

        const itemsInfo = _.get(data, 'data.info', []);
        this.items = [];
        itemsInfo.forEach(item => this.items.push({
          startDate: item.naplanovanaAktivitaDate ? moment(item.naplanovanaAktivitaDate).format('YYYY-MM-DD') : null,
          title: this.getActionTitle(item),
          ...item,
        }));
      } catch (e) {
        console.error('get nejbližších aktivit items', e.response?.data);
        this.$snotify['error'](e.response?.data?.message || 'Internal Server Error');
      } finally {
        this.isLoading = false;
      }
    },
    async formSaved() {
      await this.getItems();
      await this.$store.dispatch('getActivitiesCount');
    },
  }
}
</script>

<style scoped>
  h6 {
    font-weight: 500;
    font-size: 28px;
  }
</style>