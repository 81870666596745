<template>
  <div>
    <b-modal id="no-signed-vykaz-hodin-confirmation-modal" centered hide-footer hide-header>
      <p class="my-3 bold">Není podepsán výkaz hodin za minulý měsíc.</p>
      <div class="d-flex justify-content-end pt-3">
        <b-button class="mr-3" variant="primary" @click="redirectToVykazPracePrevMonthPage">Jdu na to!</b-button>
      </div>
    </b-modal>

    <b-modal id="no-filled-vykaz-hodin-confirmation-modal" centered hide-footer hide-header>
      <p class="my-3 bold">Nemáte vyplněný výkaz hodin již 5 dní.</p>
      <div class="d-flex justify-content-end pt-3">
        <b-button class="mr-3" variant="primary" @click="redirectToVykazPracePage">Jdu na to!</b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import moment from "moment";

export default {
  methods: {
    redirectToVykazPracePrevMonthPage() {
      const today = moment();
      const previousMonth = today.subtract(1, 'months');
      const period = previousMonth.format('MM.YYYY');
      this.$bvModal.hide('no-signed-vykaz-hodin-confirmation-modal');
      this.$router.push({
        path: "/kalendar/vykaz-prace",
        query: { period }
      });
    },
    redirectToVykazPracePage() {
      this.$bvModal.hide('no-filled-vykaz-hodin-confirmation-modal');
      this.$router.push({ path: "/kalendar/vykaz-prace" });
    },
  },
};
</script>