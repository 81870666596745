<template>
  <b-card :class="[activity.activityDone ? 'activity-done' : '']" class="mb-3">
    <div v-if="showLineTimeType(activity) || activity.naplanovanaAktivitaDate || (canSeeObchodniZastupce() && getObchodniZastupceName(activity))" class="bold">
            <span
                v-if="canSeeObchodniZastupce() && getObchodniZastupceName(activity)"
                class="oz-user-line"
            >{{ getObchodniZastupceName(activity)}}</span>
      <span v-if="activity.naplanovanaAktivitaDate && showDate" class="date-activity">{{getDateActivityLine(activity)}}</span>
      <span v-if="showLineTimeType(activity)">{{getTimeActivityLine(activity)}}</span>
    </div>
    <div v-if="popisAktivity(activity)">
      <b-icon-telephone-forward-fill v-if="activity.activityType === 'phoneCall'" class="activity-icon-type" :title="getAltActivity(activity)" />
      <b-icon-envelope-fill v-else-if="activity.activityType === 'email'" envelope-plus-fill class="activity-icon-type" :title="getAltActivity(activity)" />
      <b-icon-person-lines-fill v-else-if="activity.activityType === 'meeting'" class="activity-icon-type" :title="getAltActivity(activity)" />
      <b-icon-star-half v-else-if="activity.activityType === 'other'" class="activity-icon-type" :title="getAltActivity(activity)" />
      <span class="title-grey">Popis aktivity:</span>
      {{ popisAktivity(activity) }}
    </div>
    <div class="bold">
      {{ activity.orderNumber }}
    </div>
    <div>
      <span class="title-grey">Adresa instalace:</span> {{ getFullAddress(activity) }}
    </div>
    <div v-if="personaEmail(activity)">
      <span class="title-grey">Emailová adresa: </span>
      <a :href="getMailTo(activity)" title="Poslat email">
        {{ personaEmail(activity) }}
      </a>
    </div>
    <div>
      <span class="bold">{{ getPersonaNameTitul(activity) }},</span>
      <span class="title-grey"> telefonní číslo:</span> {{ personaPhone(activity) }}
    </div>
    <div class="d-flex pt-1">
      <b-form-checkbox
          :id="indx + 'activityDone'"
          v-model="activity.activityDone"
          class="up-checkbox"
          @change="changeActivityDone(activity)"
      >
        <span class="pt-2px">Splněno</span>
      </b-form-checkbox>
      <a
          href="#"
          @click="showOPwithZakazka(activity)"
          class="ml-4 light-green-1 badge badge-color-1"
      >
        celý OP
      </a>
      <a v-if="$store.getters.isStavObchodnihoPripaduAllowed"
         href="#"
         @click="showStavObchodnihoPripaduForm(activity)"
         class="ml-3 dark-green badge badge-color-3"
      >
        stav OP
      </a>
      <a v-if="$store.getters.isGDPRRodinnePrislusniciAllowed"
         href="#"
         @click="showGDPRForm(activity)"
         class="ml-3 dark-green badge badge-color-3"
      >
        kontakty
      </a>
      <a v-if="$store.getters.isPoznamkyKobchodnimuPripaduAllowed"
         href="#"
         @click="showPoznamkyKobchodnimuPripaduForm(activity)"
         class="ml-3 dark-green badge badge-color-3"
      >
        popis
      </a>
    </div>
  </b-card>
</template>

<script>
import axios from "axios";
import commonMixin from "@/mixins/common.mixin.ts";
import _ from "lodash";
import moment from "moment/moment";
import {mapGetters} from "vuex";
import mailtoLink from "mailto-link";
import {TRIENERGO_ASSISTENT_EMAIL} from "@/constants/form.constants";

export default {
  mixins: [commonMixin],
  computed: {
    isHaveAccess() {
      return this.$store.getters.isObchodPageAllowed;
    },
    ...mapGetters(['getUserNamesAndTitles']),
  },
  props: {
    indx: { type: Number, required: true },
    activity: { type: Object, required: true },
    activityTypeCodes: { type: Object, required: true },
    obchodniZastupceListById: { type: Object, required: true },
    showDate: { type: Boolean, default: false },
  },
  data() {
    return {

    }
  },
  async mounted() {
  },
  methods: {
    getDateActivityLine(activity = {}) {
      moment.locale('cs_CZ')
      return this.showDate ? moment(activity?.naplanovanaAktivitaDate).format('DD MMMM, YYYY') : '';
    },
    getTimeActivityLine(activity = {}) {
      const activities = [];
      const timeStr = this.getTimeActivityString(activity?.timeForActivity);
      activities.push(timeStr);
      const type = _.get(this.activityTypeCodes, activity?.activityType, '');
      activities.push(type);
      return activities.filter(a => a).join(' - ');
    },
    showLineTimeType(activity = {}) {
      return !!(activity?.timeForActivity || activity?.activityType);
    },
    getPersonaOne(activity) {
      const personasInfo = _.get(activity, 'personasInfo', []);
      if (personasInfo.length) return personasInfo[0];
      return activity;
    },
    getPersonaNameTitul(activity) {
      return this.joinName(this.getPersonaOne(activity));
    },
    personaEmail(activity) {
      return this.getPersonaOne(activity)?.email || '';
    },
    getMailTo(activity) {
      return  mailtoLink({
        to: this.getPersonaOne(activity)?.email || '',
        cc: TRIENERGO_ASSISTENT_EMAIL,
      });
    },
    personaPhone(activity) {
      return this.getPersonaOne(activity)?.phone ? this.formatPhoneNumber(this.getPersonaOne(activity).phone) : '';
    },
    popisAktivity(activity) {
      return _.get(activity, 'popisAktivity', '');
    },
    getAltActivity(activity) {
      return _.get(this.activityTypeCodes, activity?.activityType, '');
    },
    showStavObchodnihoPripaduForm(activity) {
      this.$emit('show-stav-obchodniho-pripadu-form', activity?.contractId, activity?.kodZakazky);
    },
    showGDPRForm(activity) {
      this.$emit('show-gdpr-form', activity?.contractId, activity?.kodZakazky);
    },
    showPoznamkyKobchodnimuPripaduForm(activity) {
      this.$emit('show-poznamky-k-obchodnimu-pripadu', activity?.contractId, activity?.kodZakazky);
    },
    async showOPwithZakazka(activity) {
      window.open(`/obchodni-pripady?kod=${activity?.kodZakazky}`, '_blank');
    },
    canSeeObchodniZastupce() {
      return !this.$store.getters.isCanSeeOnlyForOwn;
    },
    getObchodniZastupceName(activity) {
      return  _.get(this, `obchodniZastupceListById.${activity?.obchodniZastupceId}`, '');
    },
    async saveChangeStatusActivity(activity) {
      const url = `/calendar/changeActivityDone`;
      await axios.post(url, { id: activity._id, activityDone: !!activity?.activityDone });
    },
    async saveChangeStatusActivityMsg(activity) {
      try {
        if (!activity?.activityDone) return;

        const url = `/obchod/saveChangeStatusActivityMsg`;
        const curDate = moment().format('DD.MM.YYYY');
        const aktivita = activity?.popisAktivity?.replace(/<br>/g, ' ')?.replace(/\n/g, ' ') || '---';
        const msgToComment = `<p>\n<b>${curDate}</b>\n${this.getUserNamesAndTitles} - aktivita "${aktivita}" - ${activity?.activityType || ''} - splněno</p>`;
        const msgToHistory = `<b>${curDate}</b><br>${this.getUserNamesAndTitles} - aktivita "${aktivita}" - ${activity?.activityType  || ''} - splněno`;

        const body = {msgToComment, msgToHistory, id: activity.contractId};
        await axios.post(url, body);
      } catch (e) {
        console.error('Save activity info', e.response?.data);
      }
    },
    async changeActivityDone(activity) {
      await this.saveChangeStatusActivity(activity);
      await this.saveChangeStatusActivityMsg(activity);
      this.$emit('form-saved');
    },
    formatPhoneNumber(phoneNumber) {
      const cleanPhoneNumber = phoneNumber.replace(/\D/g, '');
      return cleanPhoneNumber.slice(0, 3) + ' ' + cleanPhoneNumber.slice(3, 6) + ' ' + cleanPhoneNumber.slice(6);
    }
  }
}
</script>

<style scoped>
.activity-done {
  color: darkgrey;
}
.activity-icon-type {
  margin-right: 7px;
  color: #17a2b8;
}
.oz-user-line {
  background-color: #fbbc04;
  font-weight: 400;
  margin-right: 6px;
  padding: 1px 5px;
}
.activity-done .oz-user-line {
  color: grey;
}
.card-body {
  padding: 0.75rem;
}
.date-activity {
  color: grey;
  padding-right: 5px;
}
</style>