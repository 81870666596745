<template>
  <b-modal
      v-model="showLocal"
      scrollable centered hide-footer no-close-on-backdrop
      dialog-class="poznamky-form"
      @hidden="$emit('close-modal')"
  >
    <template #modal-title>
      <div class="text-center">
        <h6 class="text-nowrap w-100">{{ `Poznámky k ${ title } č. ${kodZakazky}` }}</h6>
      </div>
    </template>

    <b-overlay :show="isLoading" rounded="sm">
      <div class="modalWindow-w100" @click.stop>
        <div class="d-flex">
          <vue-editor
              v-if="formData.pripojit"
              id="editor0"
              v-model="linkedComment"
              :editorOptions="editor0Settings"
              ref="editor10"
              class="w-40 pr-4"
          />
          <vue-editor
              id="editor1"
              v-model="formData.comment"
              :editorOptions="editorSettings"
              :class="formData.pripojit ? 'w-60' : 'w-100'"
              ref="editor"
          />
        </div>

        <div class="d-flex mt-3 w-100 justify-content-between">
          <div class="d-flex w-100 justify-content-between">
            <div class="w-35">
              <div class="d-flex">
                <ValidationProvider rules="min:8|max:10" name="'Číslo případu'" v-slot="{ classes, errors }">
                  <b-form-input
                      id="kodZakazkyPripojit"
                      type="text"
                      size="sm"
                      v-model="formData.kodZakazkyPripojit"
                      class="w-130px mr-2"
                      placeholder="Číslo případu"
                      :title="errors[0]"
                      :state="getState(classes) && isKodZakazkyExist"
                      @input="validateKodZakazkyOnExist"
                      :disabled="formData.pripojit"
                  />
                </ValidationProvider>
                <b-form-checkbox
                    id="pripojit"
                    v-model="formData.pripojit"
                    class="ml-1 pt-label"
                    :disabled="!formData.kodZakazkyPripojit || isShowKodZakazkyErr"
                    @change="validateKodZakazkyOnExist"
                >
                  připojit
                </b-form-checkbox>
              </div>
              <div class="errMsg" v-if="isShowKodZakazkyErr">
                Neplatný kód obchodního případu
              </div>
            </div>
            <div class="w-35>">
              <b-button @click="onSubmit()" class="" variant="primary">Uložit data a zavřít</b-button>
            </div>
            <div class="w-35 align-right pt-1">
              <a href="#" title="stáhnout textový soubor" @click="downloadTXT()">
                {{ fileName }}
                <b-icon-download class="pl-2" variant="info" font-scale="1.5" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </b-overlay>
  </b-modal>
</template>

<script>
import axios from "axios";
import { VueEditor } from "vue2-editor";
import Axios from "axios";
import {MIME_TYPES, SECTION_NAMES} from "@/constants/form.constants";
import commonMixin from "@/mixins/common.mixin.ts";

const sectionsInfo = {
  [SECTION_NAMES.SERVIS]: {
    title: 'servisu',
    saveUrl: '/service/savePoznamkyKservisuForm',
    getUrl: '/service/getPoznamkyKservisuForm',
    downloadTxtUrl: '/service/downloadCommentAsTxt',
    getPripojitCisloPripaduCommentUrl: '/service/getPripojitCisloPripaduComment',
  },
  [SECTION_NAMES.OBCHODNI_PRIPADY]: {
    title: 'obchodnímu případu',
    saveUrl: '/obchod/savePoznamkyKobchodnimuPripaduForm',
    getUrl: '/obchod/getPoznamkyKobchodnimuPripaduForm',
    downloadTxtUrl: '/obchod/downloadCommentAsTxt',
    getPripojitCisloPripaduCommentUrl: '/obchod/getPripojitCisloPripaduComment',
  },
};

export default {
  mixins: [commonMixin],
  components: {
    VueEditor
  },
  computed: {
    isShowKodZakazkyErr() {
      if (this.formData.kodZakazkyPripojit && this.formData.pripojit) { return false; }
      return !this.isKodZakazkyExist && !!this.formData.kodZakazkyPripojit && !this.isQuery;
    },
    isService() {
      return this.section === SECTION_NAMES.SERVIS;
    },
    fileName() {
      const servis = this.isService ? '_servis' : '';
      return [this.kodZakazky, servis, '.txt'].join('');
    }
  },
  props: {
    id: { type: String },
    kodZakazky: { type: String },
    show: { type: Boolean },
    section: { type: String, default: SECTION_NAMES.OBCHODNI_PRIPADY },
  },
  data() {
    return {
      formData: {
        comment: null,
        kodZakazkyPripojit: null,
        kodZakazkyPripojitId: null,
        pripojit: false,
      },
      isKodZakazkyExist: false,
      isQuery: false,
      showDate: false,
      isLoading: false,
      showLocal: false,
      linkedComment: null,
      editorSettings: {
        scrollingContainer: 'html',
        modules: {
          toolbar: [
            // [{ 'header': [1, 2, 3, 4, false] }],
            ['bold', 'italic', 'underline', 'strike'],
            // [{ 'align': [] }],
            [{ 'list': 'ordered'}, { 'list': 'bullet' }],
            [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
            // [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
            ['clean']                                         // remove formatting button
          ],
        },
      },
      editor0Settings: {
        modules: {
          toolbar: false,
        },
        scrollingContainer: 'html',
        readOnly: true,
      },
      title: '',
    }
  },
  async mounted() {
    this.isLoading = true;
    this.title = this.getTitleName();
    await this.getSavedData();
    await this.getPripojitCisloPripaduComment();
    this.isLoading = false;
    this.showLocal = this.show;
    this.$nextTick(() => {
      setTimeout(() => {
       this.scrollEditorToEnd('editor1');
      }, 200);
    });
    if (this.formData.pripojit) {
      this.$nextTick(() => {
        setTimeout(() => {
          this.scrollEditorToEnd('editor0');
        }, 200);
      });
    }
  },
  methods: {
    async getSavedData() {
      try {
        if (!this.id) { return; }

        const url = sectionsInfo[this.section].getUrl;
        const { data: {data} } = await axios.get(url, {params: {id: this.id}});
        const info = data?.info || {};
        this.formData = {...this.formData, ...info};
        this.formData.comment = this.escapeLikeTagsText(this.formData?.comment);
      } catch (e) {
        console.log('getSavedData', e.response?.data);
        this.$snotify['error'](e.response?.data?.message || 'Internal Server Error');
      }
    },
    async saveComment() {
      try {
        const url = sectionsInfo[this.section].saveUrl;
        const {data} = await axios.post(url, {id: this.id, data: this.formData});
        this.$snotify[data.status](data.data.message);
        return data.status;
      } catch (e) {
        console.log('saveComment', e.response?.data);
        this.$snotify['error'](e.response?.data?.message || 'Internal Server Error');
      }
    },
    async getPripojitCisloPripaduComment() {
      try {
        if (!this.formData.kodZakazkyPripojit || !this.formData.pripojit) return;

        const url = sectionsInfo[this.section].getPripojitCisloPripaduCommentUrl;
        const {data} = await axios.get(url, {params: {pripad: this.formData.kodZakazkyPripojit}});
        this.linkedComment = data.data?.comment || '';
      } catch (e) {
        console.log('getPripojitCisloPripaduComment', e.response?.data);
      }
    },
    async onSubmit() {
      try {
        const status = await this.saveComment();
        if (status === 'success') {
          this.$emit('form-saved')
          this.$emit('close-modal');
        }
      } catch (e) {
        console.error('Change Poznámky k obchodnímu případu', e);
        throw new Error()
      }
    },
    async downloadTXT() {
      try {
        await this.saveComment();
        const url = sectionsInfo[this.section].downloadTxtUrl;
        const response = await axios.get(url, {params: {id: this.id}}, {
          responseType: 'blob',
        });

        const blob = new Blob([response.data], { type: MIME_TYPES.txt });

        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = this.fileName;
        link.click();

      } catch (e) {
        console.error('Download a comment as txt file', e);
        throw new Error()
      }
    },
    async validateKodZakazkyOnExist() {
      if ((this.formData.kodZakazkyPripojit?.length || 0) < 8) {
        this.isKodZakazkyExist = false;
        return;
      }

      this.isQuery = true;
      const url = `/obchod/checkExistKodZakazky`;
      const { data = {} } = await Axios.post(url, {kodZakazky: this.formData.kodZakazkyPripojit, id: this.id});
      this.isKodZakazkyExist = data.data?.isExist;
      this.formData.kodZakazkyPripojitId = data.data?._id;
      this.isQuery = false;
      await this.getPripojitCisloPripaduComment();
    },
    scrollEditorToEnd(editorId = 'editor1') {
      const editor = document.getElementById(editorId);

      const elemsP = editor.getElementsByTagName('p');
      if (!elemsP.length) return;

      // Scroll to the bottom
      editor.scrollTo({
        top: editor.scrollHeight,
        behavior: 'smooth'
      });
      const lastP = elemsP[elemsP.length - 1];
      const range = document.createRange();
      range.selectNodeContents(lastP);
      range.collapse(false);
      const selection = window.getSelection();
      selection.removeAllRanges();
      selection.addRange(range);

      // Solve the problem of scrolling to the top when pasting
      function findEditorScrollContainer(quill) {
        let el = quill.root;
        while (el) {
          if (el?.scrollTop > 0) { break; }
          el = el.parentElement;
        }
        return el;
      }

      const editorQ = this.$refs.editor;
      const quill = editorQ.quill;
      if (!quill) return;

      quill.on('editor-change', () => {
        quill.scrollingContainer = findEditorScrollContainer(quill);
      });
    },

    getState({invalid, validated}, skip = false) {
      if (skip) return null;
      return validated ? !invalid : null;
    },

    getTitleName() {
      return sectionsInfo[this.section].title;
    },
  },
}
</script>

<style scoped>
  h6 {
    font-weight: 500;
    font-size: 28px;
  }
</style>