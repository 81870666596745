import Vuex from "vuex";
import Vue from "vue";
import auth from "./modules/auth.module";
import contract from "./modules/contract.module";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    contract,
  },
  plugins: [],
});
