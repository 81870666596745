export const MESSAGES = {
    SELECT_FROM_LIST: 'Vyberte prosím hodnotu ze seznamu',
}

export const MONTH_NAMES = [
  'leden', 'únor', 'březen', 'duben', 'květen', 'červen', 'červenec', 'srpen', 'září', 'říjen', 'listopad', 'prosinec'
];

export const TYPE_OF_WORKS_PROJEKCE = [
  {key: 'noveZakazkyRDBD', text: 'Nové zakázky RD, BD', class: 'font-weight-bold'},
  {key: 'podepsanoSmluvRDBD', text: 'Podepsáno smluv, nové / light / rozšíření'},
  {key: 'PVSOL', text: 'PV*SOL, nové / rozšíření'},
  {key: 'completionDatePD', text: 'Komplety PD (KM+JPS+TZd), nové / rozšíření'},
  {key: 'dateSigningEH', text: 'EH (nové zakázky)'},
  {key: 'reqConnectDistrib', text: 'Odesláno žádostí o smlouvu s distributorem'},
  {key: 'sendingSignedContractPD', text: 'Odesláno podepsaných smluv s distributorem'},
  {key: 'sendingDateNZU', text: 'Odesláno žádostí o dotace na NZÚ'},

  {key: 'dokonceniRealizaceRDBD', text: 'Dokončení realizace RD, BD', class: 'font-weight-bold'},
  {key: 'PVSOLaktualizace', text: 'PV*SOL (aktualizace)'},
  {key: 'completingDateDSPS', text: 'Komplety DSPS (JPS+TZr)'},
  {key: 'updateDateEH', text: 'EH (aktualizace)'},
  {key: 'deliveryElectricalInspectionDate', text: 'Dodání revize elektro'},
  {key: 'sendingParallelConnectionDate', text: 'Odesláno žádostí o PPP'},
  {key: 'connectionDate', text: 'Protokol o PPP'},
  {key: 'sendingReimbursementDate', text: 'Odesláno žádosti o proplacení dotace'},
  {key: 'reimbursementTermination', text: 'Proplacení dotace, ukončení zakázky'},
]

export const TYPE_OF_WORKS_OBCHOD = [
  {key: 'lineRD', text: 'Rodinné domy (RD)', class: 'font-weight-bold'},
  {key: 'podepsanoSmluvRD', text: 'Podepsáno smluv, nové / light / rozšíření'},
  {key: 'prumernaHodnotaRD', text: 'Průměrná hodnota (bez DPH)'},
  {key: 'soucetHodnotRD', text: 'Součet hodnot (bez DPH)'},
  {key: 'prohryRD', text: 'Prohry'},

  {key: 'lineBD', text: 'Bytové domy (BD)', class: 'font-weight-bold'},
  {key: 'podepsanoSmluvBD', text: 'Podepsáno smluv, nové / rozšíření'},
  {key: 'prumernaHodnotaBD', text: 'Průměrná hodnota (bez DPH)'},
  {key: 'soucetHodnotBD', text: 'Součet hodnot (bez DPH)'},
  {key: 'prohryBD', text: 'Prohry'},

  {key: 'lineFirmy', text: 'FIRMY a veřejnoprávní subjekty', class: 'font-weight-bold'},
  {key: 'podepsanoSmluvFirmyVS', text: 'Podepsáno smluv, nové / rozšíření'},
  {key: 'prumernaHodnotaFirmyVS', text: 'Průměrná hodnota (bez DPH)'},
  {key: 'soucetHodnotFirmyVS', text: 'Součet hodnot (bez DPH)'},
  {key: 'prohryFirmyVS', text: 'Prohry'},
]

export const TYPE_OF_WORKS_REALIZACE = [
  {key: 'lineRD', text: 'Rodinné domy (RD)', class: 'font-weight-bold'},
  {key: 'panelyVsechTypuRD', text: 'Panely (všech typů)'},
  {key: 'stridaceSitoveVsechTypuRD', text: 'Střídače síťové (všech typů)'},
  {key: 'stridaceHibridniVsechTypuRD', text: 'Střídače hybridní (všech typů)'},
  {key: 'baterieVsechTypuRD', text: 'Baterie (všech typů)'},
  {key: 'doconcenoInstalaciRD', text: 'Dokončeno instalací (vč. podepsání protokolů)'},
  {key: 'implemStartDateRD', text: 'Zahájeno nových instalací'},

  {key: 'lineBD', text: 'Bytové domy (BD)', class: 'font-weight-bold'},
  {key: 'panelyVsechTypuBD', text: 'Panely (všech typů)'},
  {key: 'stridaceSitoveVsechTypuBD', text: 'Střídače síťové (všech typů)'},
  {key: 'stridaceHibridniVsechTypuBD', text: 'Střídače hybridní (všech typů)'},
  {key: 'baterieVsechTypuBD', text: 'Baterie (všech typů)'},
  {key: 'doconcenoInstalaciBD', text: 'Dokončeno instalací (vč. podepsání protokolů)'},
  {key: 'implemStartDateBD', text: 'Zahájeno nových instalací'},

  {key: 'lineFirmy', text: 'FIRMY a veřejnoprávní subjekty', class: 'font-weight-bold'},
  {key: 'panelyVsechTypuFirmyVS', text: 'Panely (všech typů)'},
  {key: 'stridaceSitoveVsechTypuFirmyVS', text: 'Střídače síťové (všech typů)'},
  {key: 'stridaceHibridniVsechTypuFirmyVS', text: 'Střídače hybridní (všech typů)'},
  {key: 'baterieVsechTypuFirmyVS', text: 'Baterie (všech typů)'},
  {key: 'doconcenoInstalaciFirmyVS', text: 'Dokončeno instalací (vč. podepsání protokolů)'},
  {key: 'implemStartDateFirmyVS', text: 'Zahájeno nových instalací'},
]

export const FILTER_TYPES = {
  NAZEV_V_ADRESARE: 'nazevVadresare',
  OBJECT_OBCHODNIHO_PRIPADU: 'object',
  OBJECT_SERVISNIHO_PRIPADU: 'objectServis',
  STAV_OBCHODNIHO_PRIPADU: 'stav',
  STAV_SERVISNIHO_PRIPADU: 'stavServis',
  OKRES_OP: 'okresOP',
  OKRES_SERVISNIHO_PRIPADU: 'okresServis',
  KRAJ_OBCHODNIHO_PRIPADU: 'kraj',
  KRAJ_SERVISNIHO_PRIPADU: 'krajServis',
  OZ_USERS_ACTIVE: 'ozUsersActive',
  OZ_USERS_DEACTIVATED: 'ozUsersDisabled',
  MANAGER_KOORDINATOR: 'managerKoordinator',
  MULTIFILTR: 'multifiltr',
  NENAPLANOVANE_AKTIVITY: 'nenaplanovaneAktivity',
  NESPLNENE_AKTIVITY: 'nesplneneAktivity',
  ZIP: 'zip',
  PHONE: 'phone',
  OBCHODNI_ROZPRACOVANOST: 'obchodniRozpracovanost',
  TYP_AKTIVITY: 'typAktivity',
  ZAMESTNANEC: 'zamestnanec',
  KATEGORIE_PRIPADU: 'kategoriePripadu'
}

const FILTER_TEXTS = {
  NAZEV_V_ADRESARE: 'Název adresáře v zakázkách',
  OBJECT_OBCHODNIHO_PRIPADU: 'Objekt obchodního případu',
  OBJECT_SERVISNIHO_PRIPADU: 'Objekt servisního případu',
  TYP_OBJEKTU: 'Typ objektu',
  KATEGORIE_PRIPADU: 'Kategorie případu',
  STAV_OBCHODNIHO_PRIPADU: 'Stav obchodního případu',
  STAV_SERVISNIHO_PRIPADU: 'Stav servisního případu',
  OKRES_OP: 'Okres obchodního případu',
  OKRES_SERVISNIHO_PRIPADU: 'Okres servisního případu',
  KRAJ_OBCHODNIHO_PRIPADU: 'Kraj obchodního případu',
  KRAJ_SERVISNIHO_PRIPADU: 'Kraj servisního případu',
  OZ_USERS_ACTIVE: 'Obchodní zástupci (aktivní)',
  OZ_USERS_DEACTIVATED: 'Obchodní zástupci (deaktivované)',
  MANAGER_KOORDINATOR: 'Projektový manažer / koordinátor',
  MULTIFILTR: 'Multifiltr',
  NENAPLANOVANE_AKTIVITY: 'Nenaplánované aktivity',
  NESPLNENE_AKTIVITY: 'Nesplněné aktivity',
  ZIP: 'Poštovní směrovací číslo (PSČ)',
  PHONE: 'Telefonní číslo',
  TYP_AKTIVITY: 'Typ aktivity',
  ZAMESTNANEC: 'Zaměstnanec',
}

export const LIST_OBCH_PRIPAD_FILTER_TYPES = [
  { value: FILTER_TYPES.NAZEV_V_ADRESARE, text: FILTER_TEXTS.NAZEV_V_ADRESARE },
  { value: FILTER_TYPES.OBJECT_OBCHODNIHO_PRIPADU, text: FILTER_TEXTS.OBJECT_OBCHODNIHO_PRIPADU },
  { value: FILTER_TYPES.STAV_OBCHODNIHO_PRIPADU, text: FILTER_TEXTS.STAV_OBCHODNIHO_PRIPADU },
  { value: FILTER_TYPES.OKRES_OP, text: FILTER_TEXTS.OKRES_OP },
  { value: FILTER_TYPES.KRAJ_OBCHODNIHO_PRIPADU, text: FILTER_TEXTS.KRAJ_OBCHODNIHO_PRIPADU },
  { value: FILTER_TYPES.ZIP, text: FILTER_TEXTS.ZIP },
  { value: FILTER_TYPES.PHONE, text: FILTER_TEXTS.PHONE },
  { value: "", text: "---", disabled: true },
  { value: FILTER_TYPES.OZ_USERS_ACTIVE, text: FILTER_TEXTS.OZ_USERS_ACTIVE },
  { value: FILTER_TYPES.OZ_USERS_DEACTIVATED, text: FILTER_TEXTS.OZ_USERS_DEACTIVATED },
  { value: FILTER_TYPES.MANAGER_KOORDINATOR, text: FILTER_TEXTS.MANAGER_KOORDINATOR },
  { value: "", text: "---", disabled: true },
  { value: FILTER_TYPES.MULTIFILTR, text: FILTER_TEXTS.MULTIFILTR },
]

export const LIST_AKTIVITY_OBCHODU_FILTER_TYPES = [
  { value: FILTER_TYPES.NAZEV_V_ADRESARE, text: FILTER_TEXTS.NAZEV_V_ADRESARE },
  { value: FILTER_TYPES.KRAJ_OBCHODNIHO_PRIPADU, text: FILTER_TEXTS.KRAJ_OBCHODNIHO_PRIPADU },
  { value: FILTER_TYPES.TYP_AKTIVITY, text: FILTER_TEXTS.TYP_AKTIVITY },
  { value: "", text: "---", disabled: true },
  { value: FILTER_TYPES.OZ_USERS_ACTIVE, text: FILTER_TEXTS.OZ_USERS_ACTIVE },
  { value: FILTER_TYPES.OZ_USERS_DEACTIVATED, text: FILTER_TEXTS.OZ_USERS_DEACTIVATED },
]

export const LIST_SLED_ZAKAZEK_FILTER_TYPES = [
  { value: FILTER_TYPES.NAZEV_V_ADRESARE, text: FILTER_TEXTS.NAZEV_V_ADRESARE },
  { value: FILTER_TYPES.OBJECT_OBCHODNIHO_PRIPADU, text: FILTER_TEXTS.TYP_OBJEKTU },
  { value: FILTER_TYPES.KATEGORIE_PRIPADU, text: FILTER_TEXTS.KATEGORIE_PRIPADU },
  { value: FILTER_TYPES.OKRES_OP, text: FILTER_TEXTS.OKRES_OP },
  { value: FILTER_TYPES.KRAJ_OBCHODNIHO_PRIPADU, text: FILTER_TEXTS.KRAJ_OBCHODNIHO_PRIPADU },
  { value: FILTER_TYPES.ZIP, text: FILTER_TEXTS.ZIP },
  { value: FILTER_TYPES.PHONE, text: FILTER_TEXTS.PHONE },
  { value: "", text: "---", disabled: true },
  { value: FILTER_TYPES.OZ_USERS_ACTIVE, text: FILTER_TEXTS.OZ_USERS_ACTIVE },
  { value: FILTER_TYPES.OZ_USERS_DEACTIVATED, text: FILTER_TEXTS.OZ_USERS_DEACTIVATED },
  { value: FILTER_TYPES.MANAGER_KOORDINATOR, text: FILTER_TEXTS.MANAGER_KOORDINATOR },
]

export const LIST_REPORT_INSTALACI_FILTER_TYPES = [
  { value: FILTER_TYPES.NAZEV_V_ADRESARE, text: FILTER_TEXTS.NAZEV_V_ADRESARE },
  { value: FILTER_TYPES.OBJECT_OBCHODNIHO_PRIPADU, text: FILTER_TEXTS.TYP_OBJEKTU },
  { value: FILTER_TYPES.KATEGORIE_PRIPADU, text: FILTER_TEXTS.KATEGORIE_PRIPADU },
  { value: FILTER_TYPES.OKRES_OP, text: FILTER_TEXTS.OKRES_OP },
  { value: FILTER_TYPES.KRAJ_OBCHODNIHO_PRIPADU, text: FILTER_TEXTS.KRAJ_OBCHODNIHO_PRIPADU },
  { value: FILTER_TYPES.ZIP, text: FILTER_TEXTS.ZIP },
  { value: FILTER_TYPES.PHONE, text: FILTER_TEXTS.PHONE },
  { value: "", text: "---", disabled: true },
  { value: FILTER_TYPES.OZ_USERS_ACTIVE, text: FILTER_TEXTS.OZ_USERS_ACTIVE },
  { value: FILTER_TYPES.OZ_USERS_DEACTIVATED, text: FILTER_TEXTS.OZ_USERS_DEACTIVATED },
  { value: FILTER_TYPES.MANAGER_KOORDINATOR, text: FILTER_TEXTS.MANAGER_KOORDINATOR },
]

export const LIST_SERVIS_FILTER_TYPES = [
  { value: FILTER_TYPES.NAZEV_V_ADRESARE, text: FILTER_TEXTS.NAZEV_V_ADRESARE },
  { value: FILTER_TYPES.OBJECT_SERVISNIHO_PRIPADU, text: FILTER_TEXTS.OBJECT_SERVISNIHO_PRIPADU },
  { value: FILTER_TYPES.KATEGORIE_PRIPADU, text: FILTER_TEXTS.KATEGORIE_PRIPADU },
  { value: FILTER_TYPES.STAV_SERVISNIHO_PRIPADU, text: FILTER_TEXTS.STAV_SERVISNIHO_PRIPADU },
  { value: FILTER_TYPES.OKRES_SERVISNIHO_PRIPADU, text: FILTER_TEXTS.OKRES_SERVISNIHO_PRIPADU },
  { value: FILTER_TYPES.KRAJ_SERVISNIHO_PRIPADU, text: FILTER_TEXTS.KRAJ_SERVISNIHO_PRIPADU },
  { value: FILTER_TYPES.ZIP, text: FILTER_TEXTS.ZIP },
  { value: FILTER_TYPES.PHONE, text: FILTER_TEXTS.PHONE },
]

export const LIST_UKOLOVANI_OZ_FILTER_TYPES = [
  { value: FILTER_TYPES.NENAPLANOVANE_AKTIVITY, text: FILTER_TEXTS.NENAPLANOVANE_AKTIVITY },
  { value: FILTER_TYPES.NESPLNENE_AKTIVITY, text: FILTER_TEXTS.NESPLNENE_AKTIVITY },
]

export const LIST_OBCHODNI_ROZPRACOVANOST_FILTER_TYPES = [
  { value: FILTER_TYPES.OZ_USERS_ACTIVE, text: FILTER_TEXTS.OZ_USERS_ACTIVE },
]

export const LIST_VYKAZ_PRACE_FILTER_TYPES = [
  { value: FILTER_TYPES.ZAMESTNANEC, text: FILTER_TEXTS.ZAMESTNANEC },
]

export const TRIENERGO_ASSISTENT_EMAIL= 'trienergo.asistent@gmail.com';

export const BCC_ASISTENT_EMAIL = 'trienergo.asistent@gmail.com';

export const USER_TYPES = {
  OZ: 'ozUser',
  P_MANAGER: 'projManager',
  P_KOORDINATOR: 'projKoordinator',
  PROJEKTANT: 'projektant',
  PV_SOL: 'pvSol',
}

export const USER_TYPES_LIST = [
  { text: 'Obchodní zástupce (OZ)', value: USER_TYPES.OZ },
  { text: 'Projektový manažer', value: USER_TYPES.P_MANAGER },
  { text: 'Projektový koordinátor', value: USER_TYPES.P_KOORDINATOR },
  { text: 'Projektant', value: USER_TYPES.PROJEKTANT },
  { text: 'PV*SOL', value: USER_TYPES.PV_SOL },
]

export const KATEGORIE_OZ = {
  JUNIOR: 'junior',
  SENIOR: 'senior',
  INDIVIDUAL: 'individual',
}

export const KATEGORIE_OZ_LIST = [
  { value: KATEGORIE_OZ.JUNIOR, text: KATEGORIE_OZ.JUNIOR },
  { value: KATEGORIE_OZ.SENIOR, text: KATEGORIE_OZ.SENIOR },
  { value: KATEGORIE_OZ.INDIVIDUAL, text: KATEGORIE_OZ.INDIVIDUAL },
];

export const OPTIONS_ACCESS_BY_OZ = {
  ONLY_OWN: 'onlyForOwn',
  ALL: 'all',
  FOR_SELECTED_GROUP: 'forSelectedGroup',
}

export const VIDI_ZAKAZKY = [
  { value: OPTIONS_ACCESS_BY_OZ.ONLY_OWN, text: 'jen své' },
  { value: OPTIONS_ACCESS_BY_OZ.FOR_SELECTED_GROUP, text: 'vybrané skupiny' },
  { value: OPTIONS_ACCESS_BY_OZ.ALL, text: 'vše' },
];

export const OBJECT_TYPE = {
  VS: 'govOrganization',
  RD: 'familyHouse',
  BD: 'apartmentBuilding',
  FIRM: 'firm',
}

export const TABULKA_TYPE = {
  TABULKA_1: 'tabulka_1',
  TABULKA_2: 'tabulka_2'
}

export const KALENDAR_TABS = {
  KALENDAR: 'kalendar',
  VYKAZ_PRACE: 'vykazPrace',
  AKTIVITY_VLASTNI: 'aktivityVlastni',
}

export const PRIPAD_OBJECTS_TYPES = {
  VS: 'veřejnoprávní subjekt',
  RD: 'rodinný dům (RD)',
  BD: 'bytový dům (BD)',
  FIRM: 'firma',
}

export const PRIPAD_OBJECTS = [
  { value: OBJECT_TYPE.VS, text: PRIPAD_OBJECTS_TYPES.VS },
  { value: OBJECT_TYPE.RD, text: PRIPAD_OBJECTS_TYPES.RD },
  { value: OBJECT_TYPE.BD, text: PRIPAD_OBJECTS_TYPES.BD },
  { value: OBJECT_TYPE.FIRM, text: PRIPAD_OBJECTS_TYPES.FIRM },
];

export const PRIPAD_TYPES = {
  FVE: 'FVEwithBattery',
  SVR: 'SVR',
  TC: 'TC',
  OHREV_VODY: 'ohrevVody',
  NABIJECI_ELEKTROMOBILU: 'nabijeciElektromobilu',
  VICEPRACE: 'moreWork',
  ROZSIRENI: 'extension',
  SERVIS: 'servis',
}

export const PRIPAD_CATEGORIES_TYPES = {
  FVE: 'fotovoltaická elektrárna (FVE)',
  SVR: 'systémové služby (SVR)',
  TC: 'tepelné čerpadlo (TČ)',
  OHREV_VODY: 'fotovoltaický ohřev vody',
  NABIJECI_ELEKTROMOBILU: 'nabíjecí stanice elektromobilů',
  VICEPRACE: 'vícepráce',
  ROZSIRENI: 'rozšíření',
  SERVIS: 'servis'
}

export const PRIPAD_CATEGORIES = [
  { value: PRIPAD_TYPES.FVE, text: PRIPAD_CATEGORIES_TYPES.FVE },
  { value: PRIPAD_TYPES.SVR, text: PRIPAD_CATEGORIES_TYPES.SVR },
  { value: PRIPAD_TYPES.TC, text: PRIPAD_CATEGORIES_TYPES.TC },
  { value: PRIPAD_TYPES.OHREV_VODY, text: PRIPAD_CATEGORIES_TYPES.OHREV_VODY },
  { value: PRIPAD_TYPES.NABIJECI_ELEKTROMOBILU, text: PRIPAD_CATEGORIES_TYPES.NABIJECI_ELEKTROMOBILU },
  { value: PRIPAD_TYPES.VICEPRACE, text: PRIPAD_CATEGORIES_TYPES.VICEPRACE },
  { value: PRIPAD_TYPES.ROZSIRENI, text: PRIPAD_CATEGORIES_TYPES.ROZSIRENI },
  { value: PRIPAD_TYPES.SERVIS, text: PRIPAD_CATEGORIES_TYPES.SERVIS },
];

export const VERZE_SOD_TYPE = {
  KONZULTACNI_SMLOUVA: 'konzultacniSmlouva',
  PROJEKCNI_SMLOUVA: 'projekcniSmlouva',
  REALIZACNI_SMLOUVA: 'realizacniSmlouva' ,
  FAKTURA: 'faktura',
};

export const VERZE_SOD = [
  { value: VERZE_SOD_TYPE.KONZULTACNI_SMLOUVA, text: 'Konzultační smlouva (objednávka)' },
  { value: VERZE_SOD_TYPE.PROJEKCNI_SMLOUVA, text: 'Projekční smlouva (objednávka)' },
  { value: VERZE_SOD_TYPE.REALIZACNI_SMLOUVA, text: 'Realizační smlouva' },
  { value: VERZE_SOD_TYPE.FAKTURA, text: 'Faktura' },
];

export const BANK_CODES = {
  '0100': 'Komerční banka, a.s.',
  '0300': 'ČSOB, a.s.',
  '0600': 'MONETA Money Bank, a.s.',
  '0710': 'Česká národní banka',
  '0800': 'Česká spořitelna, a.s.',
  '2010': 'Fio banka, a.s.',
  '2020': 'MUFG Bank (Europe) N.V. Prague Branch',
  '2060': 'Citfin, spořitelní družstvo',
  '2070': 'TRINITY BANK a.s.',
  '2100': 'Hypoteční banka, a.s.',
  '2200': 'Peněžní dům, spořitelní družstvo',
  '2220': 'Artesa, spořitelní družstvo',
  '2250': 'Banka CREDITAS a.s.',
  '2260': 'NEY spořitelní družstvo',
  '2275': 'Podnikatelská družstevní záložna',
  '2600': 'Citibank Europe plc, organizační složka',
  '2700': 'UniCredit Bank Czech Republic and Slovakia, a.s.',
  '3030': 'Air Bank a. s.',
  '3050': 'BNP Paribas Personal Finance SA, odštěpný závod',
  '3060': 'PKO BP S.A., Czech Branch',
  '3500': 'ING Bank N.V.',
  '4000': 'Max banka a.s.',
  '4300': 'Národní rozvojová banka, a.s.',
  '5500': 'Raiffeisenbank a.s.',
  '5800': 'J&T Banka, a.s.',
  '6000': 'PPF banka a.s.',
  '6100': 'Raiffeisenbank a.s.',
  '6200': 'COMMERZBANK Aktiengesellschaft, pobočka Praha',
  '6210': 'mBank S.A., organizační složka',
  '6300': 'BNP Paribas S.A., pobočka Česká republika',
  '6700': 'Všeobecná úverová banka a.s., pobočka Praha',
  '7910': 'Deutsche Bank A.G. Filiale Prag',
  '7950': 'Raiffeisen stavební spořitelna a.s.',
  '7960': 'ČSOB Stavební spořitelna, a.s.',
  '7970': 'Moneta Stavební spořitelna a.s.',
  '7990': 'Modrá pyramida stavební spořitelna, a.s.',
  '8030': 'Volksbank Raiffeisenbank Nordoberpfalz eG pobočka Cheb',
  '8040': 'Oberbank AG pobočka Česká republika',
  '8060': 'Stavební spořitelna České spořitelny, a.s.',
  '8090': 'Česká exportní banka, a.s.',
  '8150': 'HSBC Continental Europe, Czech Republic',
  '8190': 'Sparkasse Oberlausitz-Niederschlesien',
  '8198': 'FAS finance company s.r.o.',
  '8199': 'MoneyPolo Europe s.r.o.',
  '8200': 'PRIVAT BANK AG der Raiffeisenlandesbank Oberösterreich v České republice',
  '8220': 'Payment Execution s.r.o.',
  '8230': 'ABAPAY s.r.o.',
  '8240': 'Družstevní záložna Kredit, v likvidaci',
  '8250': 'Bank of China (CEE) Ltd. Prague Branch',
  '8255': 'Bank of Communications Co., Ltd., Prague Branch odštěpný závod',
  '8265': 'Industrial and Commercial Bank of China Limited, Prague Branch, odštěpný závod',
  '8270': 'Fairplay Pay s.r.o.',
  '8280': 'B-Efekt a.s.',
  '8293': 'Mercurius partners s.r.o.',
  '8299': 'BESTPAY s.r.o.',
  '8500': 'Ferratum Bank plc',
}

export const DEN_TYPES = {
  PRACOVNI: 'pracovni',
  SVATEK: 'svatek',
  NESCHOPENKA: 'neschopenka',
  OCR: 'OCR',
  DOVOLENA: 'dovolena',
  NAHRADNI_VOLNO: 'nahradniVolno',
  NEPLACENE_VOLNO: 'neplaceneVolno',
  VIKEND: 'vikend',
}

export const TYP_DNE = [
  {value: DEN_TYPES.PRACOVNI, text: 'pracovní den'},
  {value: DEN_TYPES.SVATEK, text: 'placený státní svátek'},
  {value: DEN_TYPES.NESCHOPENKA, text: 'neschopenka'},
  {value: DEN_TYPES.OCR, text: 'OČR'},
  {value: DEN_TYPES.DOVOLENA, text: 'dovolená'},
  {value: DEN_TYPES.NAHRADNI_VOLNO, text: 'náhradní volno'},
  {value: DEN_TYPES.NEPLACENE_VOLNO, text: 'neplacené volno'},
  {value: DEN_TYPES.VIKEND, text: 'víkend'},
]

export const PRAVDEPODOBNOST_LIST = [
  { value: '1', text: '100%' },
  { value: '0.95', text: '95%' },
  { value: '0.9', text: '90%' },
  { value: '0.85', text: '85%' },
  { value: '0.8', text: '80%' },
  { value: '0.75', text: '75%' },
  { value: '0.7', text: '70%' },
  { value: '0.65', text: '65%' },
  { value: '0.6', text: '60%' },
  { value: '0.55', text: '55%' },
  { value: '0.5', text: '50%' },
  { value: '0.45', text: '45%' },
  { value: '0.4', text: '40%' },
  { value: '0.35', text: '35%' },
  { value: '0.3', text: '30%' },
  { value: '0.25', text: '25%' },
  { value: '0.2', text: '20%' },
  { value: '0.15', text: '15%' },
  { value: '0.1', text: '10%' },
  { value: '0.05', text: '5%' },
  { value: '0', text: '0%' },
];

export const UDAJE_O_OCHRANE_UZEMI_LIST = {
  OPT0: 'nejsou evidovány',
  OPT1: 'památkově chráněné území',
  OPT2: 'chráněná krajinná oblast (CHKO)',
  OPT3: 'rozsáhlé chráněné území',
  OPT4: 'ochranné pásmo vodního zdroje',
  OPT5: 'zemědělský půdní fond',
  OPT6: 'ptačí oblast',
}

export const TERMINY_PLNENI_KROKU_ZAKAZKY = {
  dniProOdeslaniRozmisteniPanelu: 5,
  dniProSchvaleniRozmisteniZakaznikem: 5,
  dniProZpracovaniKompletuPD: 5,
  dniProZpracovaniEH: 5,
  dniProPodpisuEH: 5,
  dniProZadostPripojeniDistr: 5,
  dniProPodpisSmlouvyDistr: 30,
  dniProOdeslaniPodepsaneSmlouvyDistr: 15,
  dniProOdeslaniZadostiNaNZU: 5,
  dniProSchvaleniNZU: 60,
  dniProZahajeniRealizace: 15,
  dniProDokonceniRealizaceProtokol: 90,
  dniProOdeslaniPodkladuDSPS: 5,
  dniProZpracovaniDSPS: 5,
  dniProAktualizaceEH: 5,
  dniProZadaniRevizeElektro: 3,
  dniProDodaniRevizeElektro: 20,
  dniProOdeslaniZadostiParalelniPripojeni: 5,
  dniProPripojeniProtokolPPP: 30,
  dniProOdeslaniZadostiProplaceni: 5,
  dniProProplaceniDotace: 60,
}

export const PRACOVNI_UVAZEK_TYPES = {
  HPP: 'HPP',
  DPP: 'DPP',
  DPC: 'DPC',
  ICO: 'ICO',
}

export const PRACOVNI_UVAZEK_TEXTS = {
  HPP: 'HPP',
  DPP: 'DPP',
  DPC: 'DPČ',
  ICO: 'IČO',
}

export const PRACOVNI_UVAZEK_LIST = [
  { value: PRACOVNI_UVAZEK_TYPES.HPP, text: PRACOVNI_UVAZEK_TEXTS.HPP },
  { value: PRACOVNI_UVAZEK_TYPES.DPP, text: PRACOVNI_UVAZEK_TEXTS.DPP },
  { value: PRACOVNI_UVAZEK_TYPES.DPC, text: PRACOVNI_UVAZEK_TEXTS.DPC },
  { value: PRACOVNI_UVAZEK_TYPES.ICO, text: PRACOVNI_UVAZEK_TEXTS.ICO },
];

export const MIME_TYPES = {
  docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  pdf: 'application/pdf',
  txt: 'text/plain',
  xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  xlsm: 'application/vnd.ms-excel.sheet.macroenabled.12',
  zip: 'application/zip',
  json: 'application/json',
}

export const KRAJ = [
  { value: 'Praha', text: 'Hlavní město Praha' },
  { value: 'Jihocesky', text: 'Jihočeský kraj' },
  { value: 'Jihomoravsky', text: 'Jihomoravský kraj' },
  { value: 'Karlovarsky', text: 'Karlovarský kraj' },
  { value: 'Kralovehradecky', text: 'Královehradecký kraj' },
  { value: 'Liberecky', text: 'Liberecký kraj' },
  { value: 'Moravskoslezsky', text: 'Moravskoslezský kraj' },
  { value: 'Olomoucky', text: 'Olomoucký kraj' },
  { value: 'Pardubicky', text: 'Pardubický kraj' },
  { value: 'Plzensky', text: 'Plzeňský kraj' },
  { value: 'Stredocesky', text: 'Středočeský kraj' },
  { value: 'Ustecky', text: 'Ústecký kraj' },
  { value: 'Vysocina', text: 'Vysočina' },
  { value: 'Zlinsky', text: 'Zlínský kraj' },
];

export const KRAJ_SHORT = [
  { value: 'Praha', text: 'Hlavní město Praha' },
  { value: 'Jihocesky', text: 'Jihočeský' },
  { value: 'Jihomoravsky', text: 'Jihomoravský' },
  { value: 'Karlovarsky', text: 'Karlovarský' },
  { value: 'Kralovehradecky', text: 'Královehradecký' },
  { value: 'Liberecky', text: 'Liberecký' },
  { value: 'Moravskoslezsky', text: 'Moravskoslezský' },
  { value: 'Olomoucky', text: 'Olomoucký' },
  { value: 'Pardubicky', text: 'Pardubický' },
  { value: 'Plzensky', text: 'Plzeňský' },
  { value: 'Stredocesky', text: 'Středočeský' },
  { value: 'Ustecky', text: 'Ústecký' },
  { value: 'Vysocina', text: 'Vysočina' },
  { value: 'Zlinsky', text: 'Zlínský' },
];

export const DISTRIBUTORS_CODES = {
  CEZ: 'ČEZ',
  EG_D: 'EG.D',
  PRE: 'PRE',
  LDS: 'LDS',
};

export const DISTRIBUTORS = [
  { value: DISTRIBUTORS_CODES.CEZ, text: DISTRIBUTORS_CODES.CEZ },
  { value: DISTRIBUTORS_CODES.EG_D, text: DISTRIBUTORS_CODES.EG_D },
  { value: DISTRIBUTORS_CODES.PRE, text: DISTRIBUTORS_CODES.PRE },
  { value: DISTRIBUTORS_CODES.LDS, text: DISTRIBUTORS_CODES.LDS },
];

export const SECTION_NAMES = {
  SERVIS: 'servis',
  REALIZACE: 'realizace',
  OBCHODNI_PRIPADY: 'obchodniPripady',
}

export const FILE_TYPES = {
  download: 'download',
  templates: 'templates'
};