import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
import {URL_PAGES} from "@/constants/url.constants";
import {TABULKA_TYPE, KALENDAR_TABS, FILTER_TYPES} from "@/constants/form.constants";
import _ from "lodash";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import('@/views/HomePage.vue'),
    beforeEnter(to, from, next) {
      const entitlements = store.getters.getPagesEntitlements || [];
      if (!entitlements.length) {
        return store.getters.isAdmin ? next("/uzivatele") : next();
      }

      let allowedPages = [];
      entitlements.forEach(ent => {
        if (URL_PAGES[ent]) {
          allowedPages.push(URL_PAGES[ent]);
        }
      });
      allowedPages = _.sortBy(allowedPages, ['order']);
      const urlForRedirect = allowedPages[0].url;

      next(urlForRedirect);
    },
    meta: {requiresAuth: true, title: 'Home'},
  },

  // login
  {
    path: "/login",
    name: "Přihlášení",
    component: () => import('@/views/LoginPage.vue'),
    meta: { guest: true, title: "Přihlášení" },
  },

  // sledovani-zakazek
  {
    path: "/sledovani-zakazek/tabulka-1",
    name: "Sledování zakázek",
    component: () => import('@/views/SledovaniZakazekPage.vue'),
    beforeEnter(to, from, next) {
      if (!store.getters.isSledovaniZakazekPageAllowed) return next('/');
      next();
    },
    props: { tabName: TABULKA_TYPE.TABULKA_1 },
    meta: {requiresAuth: true, title: 'Sledování zakázek'},
  },
  {
    path: "/sledovani-zakazek/tabulka-2",
    name: "Sledování zakázek",
    component: () => import('@/views/SledovaniZakazekPage.vue'),
    beforeEnter(to, from, next) {
      if (!store.getters.isSledovaniZakazekPageAllowed) return next('/');
      next();
    },
    props: { tabName: TABULKA_TYPE.TABULKA_2 },
    meta: {requiresAuth: true, title: 'Sledování zakázek'},
  },

  // report-instalace-technologie
  {
    path: "/report-instalace-technologie/tabulka-1",
    name: "Report instalací a technologie",
    component: () => import('@/views/ReportInstalaceATechnologiePage.vue'),
    beforeEnter(to, from, next) {
      if (!store.getters.isReportInstalaceATechnologiePageAllowed) return next('/');
      next();
    },
    props: { tabName: TABULKA_TYPE.TABULKA_1 },
    meta: {requiresAuth: true, title: 'Report instalací a technologie'},
  },
  {
    path: "/report-instalace-technologie/tabulka-2",
    name: "Report instalací a technologie",
    component: () => import('@/views/ReportInstalaceATechnologiePage.vue'),
    beforeEnter(to, from, next) {
      if (!store.getters.isReportInstalaceATechnologiePageAllowed) return next('/');
      next();
    },
    props: { tabName: TABULKA_TYPE.TABULKA_2 },
    meta: {requiresAuth: true, title: 'Report instalací a technologie'},
  },

  // prehled-cisel
  {
    path: "/prehled-cisel/obchod",
    name: "Přehled čísel",
    component: () => import('@/views/PrehledCiselPage.vue'),
    beforeEnter(to, from, next) {
      if (!store.getters.isPrehledCiselPageAllowed) return next('/');
      next();
    },
    props: { tabName: 'obchod' },
    meta: {requiresAuth: true, title: 'Obchod'},
  },
  {
    path: "/prehled-cisel/projekce",
    name: "Přehled čísel",
    component: () => import('@/views/PrehledCiselPage.vue'),
    beforeEnter(to, from, next) {
      if (!store.getters.isPrehledCiselPageAllowed) return next('/');
      next();
    },
    props: { tabName: 'projekce' },
    meta: {requiresAuth: true, title: 'Projekce'},
  },
  {
    path: "/prehled-cisel/realizace",
    name: "Přehled čísel",
    component: () => import('@/views/PrehledCiselPage.vue'),
    beforeEnter(to, from, next) {
      if (!store.getters.isPrehledCiselPageAllowed) return next('/');
      next();
    },
    props: { tabName: 'realizace' },
    meta: {requiresAuth: true, title: 'Realizace'},
  },

  // financni-analyza
  {
    path: '/financni-analyza/tabulka-1',
    name: "Finanční analýza",
    component: () => import('@/views/FinancniAnalyzaPage.vue'),
    beforeEnter(to, from, next) {
      if (!store.getters.isFinancniAnalyzaPageAllowed) return next('/');
      next();
    },
    props: { tabName: TABULKA_TYPE.TABULKA_1 },
    meta: {requiresAuth: true, title: 'Podepsané smlouvy'},
  },
  {
    path: '/financni-analyza/tabulka-2',
    name: "Finanční analýza",
    component: () => import('@/views/FinancniAnalyzaPage.vue'),
    beforeEnter(to, from, next) {
      if (!store.getters.isFinancniAnalyzaPageAllowed) return next('/');
      next();
    },
    props: { tabName: TABULKA_TYPE.TABULKA_2 },
    meta: {requiresAuth: true, title: 'Podepsané smlouvy'},
  },
  {
    path: '/financni-analyza/grafy',
    name: "Finanční analýza",
    component: () => import('@/views/FinancniAnalyzaPage.vue'),
    beforeEnter(to, from, next) {
      if (!store.getters.isFinancniAnalyzaPageAllowed) return next('/');
      next();
    },
    props: { tabName: 'grafy' },
    meta: {requiresAuth: true, title: 'Grafy'},
  },

  // dohled-nad-oz
  {
    path: '/dohled-nad-oz/cinnost-zastupcu',
    name: "Dohled nad OZ",
    component: () => import('@/views/DohledNadOzPage.vue'),
    beforeEnter(to, from, next) {
      if (!store.getters.isDohledNadOzPageAllowed) return next('/');
      next();
    },
    props: { tabName: 'cinnostObchodnichZastupcu' },
    meta: {requiresAuth: true, title: 'Činnost obchodních zástupců'},
  },
  {
    path: '/dohled-nad-oz/ukolovani-zastupcu',
    name: "Dohled nad OZ",
    component: () => import('@/views/DohledNadOzPage.vue'),
    beforeEnter(to, from, next) {
      if (!store.getters.isDohledNadOzPageAllowed) return next('/');
      next();
    },
    props: { tabName: 'ukolovaniObchodnichZastupcu' },
    meta: {requiresAuth: true, title: 'Úkolování obchodních zástupců'},
  },
  {
    path: '/dohled-nad-oz/obchodni-rozpracovanost',
    name: "Dohled nad OZ",
    component: () => import('@/views/DohledNadOzPage.vue'),
    beforeEnter(to, from, next) {
      if (!store.getters.isDohledNadOzPageAllowed) return next('/');
      next();
    },
    props: { tabName: FILTER_TYPES.OBCHODNI_ROZPRACOVANOST },
    meta: {requiresAuth: true, title: 'Obchodní rozpracovanost'},
  },

  // sklad
  {
    path: "/sklad",
    name: "Sklad",
    component: () => import('@/views/SkladPage.vue'),
    beforeEnter(to, from, next) {
      if (!store.getters.isSkladPageAllowed) return next('/');
      next();
    },
    meta: {requiresAuth: true, title: 'Sklad'},
  },

  // uzivatele
  {
    path: "/uzivatele",
    name: "Uživatelé",
    component: () => import('@/views/UzivatelePage.vue'),
    beforeEnter(to, from, next) {
      if (!store.getters.isUzivatelePageAllowed && !store.getters.isAdmin) return next('/');
      next();
    },
    meta: {requiresAuth: true, title: "Uživatelé"},
  },

  // logy
  {
    path: "/logy",
    name: "Logy",
    component: () => import('@/views/LogyPage.vue'),
    beforeEnter(to, from, next) {
      if (!store.getters.isLogyPageAllowed) return next('/');
      next();
    },
    meta: {requiresAuth: true, title: "Logy"},
  },

  // obchodni-pripady
  {
    path: "/obchodni-pripady",
    name: "Obchodní případy",
    component: () => import('@/views/ObchodnyPripadPage.vue'),
    beforeEnter(to, from, next) {
      if (!store.getters.isObchodPageAllowed) return next('/');
      next();
    },
    meta: {requiresAuth: true, title: 'Obchodní případy'},
  },

  {
    path: "/uschovna",
    name: "Úschovna",
    component: () => import('@/views/Uschovna.vue'),
    beforeEnter(to, from, next) {
      if (!store.getters.isUschovnaAllowed) return next('/');
      next();
    },
    meta: {requiresAuth: true, title: 'Úschovna'},
  },
  // kalendar
  {
    path: "/kalendar",
    name: "Kalendář",
    component: () => import('@/views/KalendarPage.vue'),
    beforeEnter(to, from, next) {
      if (!store.getters.isKalendarPageAllowed) return next('/');
      if (!store.getters.isAktivityObchoduAllowed) return next('/kalendar/vykaz-prace');
      next();
    },
    props: { tabName: KALENDAR_TABS.KALENDAR },
    meta: {requiresAuth: true, title: 'Kalendář'},
  },
  {
    path: "/kalendar/vykaz-prace",
    name: "Kalendář",
    component: () => import('@/views/KalendarPage.vue'),
    beforeEnter(to, from, next) {
      if (!store.getters.isKalendarPageAllowed) return next('/');
      next();
    },
    props: { tabName: KALENDAR_TABS.VYKAZ_PRACE },
    meta: {requiresAuth: true, title: 'Výkaz práce'},
  },
  {
    path: "/kalendar/aktivity-vlastni",
    name: "Kalendář",
    component: () => import('@/views/KalendarPage.vue'),
    beforeEnter(to, from, next) {
      if (!store.getters.isKalendarPageAllowed) return next('/');
      next();
    },
    props: { tabName: KALENDAR_TABS.AKTIVITY_VLASTNI },
    meta: {requiresAuth: true, title: 'Aktivity vlastní'},
  },

  // servis
  {
    path: "/servis",
    name: "Servis",
    component: () => import('@/views/ServisPage.vue'),
    beforeEnter(to, from, next) {
      if (!store.getters.isServisPageAllowed) return next('/');
      next();
    },
    meta: {requiresAuth: true, title: 'Servis'},
  },

  // knihovna
  {
    path: "/knihovna/seznam-kontaktu",
    name: "Knihovna",
    component: () => import('@/views/KnihovnaPage.vue'),
    beforeEnter(to, from, next) {next();},
    props: { tabName: 'seznamKontaktu' },
    meta: {requiresAuth: true, title: 'Seznam kontaktů'},
  },
  {
    path: "/knihovna/zakladni-dokumenty",
    name: "Knihovna",
    component: () => import('@/views/KnihovnaPage.vue'),
    beforeEnter(to, from, next) {next();},
    props: { tabName: 'zakladniDocumenty' },
    meta: {requiresAuth: true, title: 'Základní dokumenty'},
  },
  {
    path: "/knihovna/webove-stranky",
    name: "Knihovna",
    component: () => import('@/views/KnihovnaPage.vue'),
    beforeEnter(to, from, next) {next();},
    props: { tabName: 'weboveStranky' },
    meta: {requiresAuth: true, title: 'Dobré webové stránky'},
  },
{
    path: "/knihovna/smernice",
    name: "Knihovna",
    component: () => import('@/views/KnihovnaPage.vue'),
    beforeEnter(to, from, next) {next();},
    props: { tabName: 'smernice' },
    meta: {requiresAuth: true, title: 'Směrnice'},
  },
{
    path: "/knihovna/sablony-datatabulky",
    name: "Knihovna",
    component: () => import('@/views/KnihovnaPage.vue'),
    beforeEnter(to, from, next) {next();},
    props: { tabName: 'sablonyDatatabulky' },
    meta: {requiresAuth: true, title: 'Šablony a datatabulky'},
  },


  {
    path: "/nesplnene-aktivity",
    name: "Nesplněné aktivity",
    component: () => import('@/views/DohledNadOz/UkolovaniObchodnichZastupcuTab.vue'),
    beforeEnter(to, from, next) {
      next();
    },
    meta: {requiresAuth: true, title: 'Nesplněné aktivity', filterType: 'nesplneneAktivity'},
  },
  {
    path: "/nenaplanovane-aktivity",
    name: "Nenaplánované aktivity",
    component: () => import('@/views/DohledNadOz/UkolovaniObchodnichZastupcuTab.vue'),
    beforeEnter(to, from, next) {
      next();
    },
    meta: {requiresAuth: true, title: "Nenaplánované aktivity", filterType: 'nenaplanovaneAktivity'},
  },
  {
    path: "/obchodni-rozpracovanost",
    name: "Obchodní rozpracovanost",
    component: () => import('@/views/DohledNadOz/ObchodniRozpracovanostPage.vue'),
    beforeEnter(to, from, next) {
      next();
    },
    meta: {requiresAuth: true, title: "Obchodní rozpracovanost"},
  },
  {
    path: "*",
    beforeEnter(to, from, next) {
      next("/")
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BACKEND_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.getters.isAuthenticated) {
      next();
      return;
    }
    next("/login");
  } else {
    next();
  }
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.guest)) {
    if (store.getters.isAuthenticated) {
      next("/");
      return;
    }
    next();
  } else {
    next();
  }
});

router.afterEach((to) => {
  document.title = to.meta?.title ? `CRM Trienergo — ${to.meta.title}` : 'CRM Trienergo — FVE creator'; // Используйте заголовок из метаданных или установите заголовок по умолчанию
});

export default router;
